import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Biglietto mobile',
  ...features,
  save: 'Salva',
  username: 'Nome utente',
  password: "Parola d'ordine",
  login: 'Login',
  required: 'Necessario',
  light: 'Leggero',
  dark: 'Buio',
  rememberMe: 'Ricordati di me',
  eula: 'Eula',
  copyright: '© {Year} - Command Alkon, Inc.',
  privacyPolicy: 'politica sulla riservatezza',
  customer: 'Cliente',
  project: 'Progetto',
  isBillable: 'Fatturabile',
  dispatchDateTime: 'Data',
  orderId: 'Ordine',
  ticketId: 'Biglietto',
  location: 'Posizione',
  vehicleTypeName: 'Veicolo',
  product: 'Prodotto',
  products: 'Prodotto',
  salesTax: 'Imposta sulle vendite',
  totalPrice: 'Totale',
  extendedPrice: 'Ext',
  destination: 'Destinazione',
  productId: 'Codice prodotto',
  statusCode: 'Stato',
  vehicleTypeId: 'ID tipo di veicolo',
  locationId: 'Posizione ID',
  customerId: 'identificativo del cliente',
  projectId: 'ID progetto',
  dispatchTime: 'Tempo',
  showAll: 'Mostra tutto',
  hideAll: 'Nascondi tutto',
  undoChanges: 'Cancella i cambiamenti',
  resetColumns: 'Ripristina le colonne',
  displayRowCount: `Visualizzazione {count, plural, 
    =0 {0 righe} 
    one {# riga} 
    other {# righe} 
  }`,
  columnFilters: 'Filtri della colonna',
  visible: 'Visibile',
  hidden: 'Nascosto',
  itemDetail: "Dettaglio dell'articolo",
  quantity: 'Quantità',
  quantityMaxWaterToAdd: 'Quantità (Acqua massima da aggiungere: {maxWaterQuantity})',
  unloadingMethod: 'Metodo di scarico',
  time: 'Tempo',
  eventStatus: 'Stato',
  MTQ_abbr: 'm³',
  EA_abbr: 'Ea',
  LTR_abbr: 'L',
  YDQ_abbr: 'Cy',
  GLL_abbr: 'Gal',
  MTQ_text: 'Metri cubi',
  M3_text: 'Metri cubi',
  EA_text: 'Ogni',
  LTR_text: 'Litri',
  YDQ_text: 'Iarde cubiche',
  GLL_text: 'Galloni',
  ACR_text: 'Acri',
  HUR_text: 'Ora',
  INH_text: 'Pollici',
  FOT_text: 'Piede',
  YRD_text: 'Cortile',
  MMT_text: 'Millimetro',
  CMT_text: 'Centimetro',
  MTR_text: 'Metro',
  SMI_text: 'Miglio (statuto miglia)',
  KMT_text: 'Chilometro',
  INK_text: 'Pollice quadrato',
  FTK_text: 'Piede quadrato',
  YDK_text: 'Cortile quadrato',
  MIK_text: 'Miglio quadrato',
  CMK_text: 'Centimetro quadrato',
  MTK_text: 'Metro quadro',
  KMK_text: 'Chilometro quadrato',
  INQ_text: 'Pollice cubico',
  MMQ_text: 'Millimetro cubico',
  FTQ_text: 'Piede cubico',
  CMQ_text: 'Centimetro cubo',
  OZA_text: 'Oncia liquida',
  PT_text: 'Pinta',
  QT_text: 'Quart',
  MLT_text: 'Millilitro',
  ONZ_text: 'Oncia',
  LBR_text: 'Libbra',
  STN_text: 'tonnellata',
  GRM_text: 'Grammo',
  KGM_text: 'Chilogrammo',
  TNE_text: 'Tonnellata',
  PS_text: 'Psi',
  MPA_text: 'Megapascal',
  C56_text: 'Newton per millimetro quadrato',
  C62_text: 'Uno',
  NL_text: 'Carico',
  MT_text: 'Stuoia',
  P1_text: 'Per cento',
  CEL_text: 'Laurea Celsius',
  FAH_text: 'Laurea Fahrenheit',
  BLL_text: 'Barile',
  CLT_text: 'Centilitro',
  DLT_text: 'Decilitro',
  GLI_text: 'Gallone',
  '2U_text': 'Megagram',
  SA_text: 'Sacco',
  '60_text': 'Peso percentuale',
  BG_text: 'Borsa',
  SEC_text: 'Secondo',
  MIN_text: 'Minuto',
  '64_text': 'Sterlina per pollice quadrato - calibro',
  SP_text: 'Pacchetto scaffale',
  MMK_text: 'Millimetro quadrato',
  MTN_text: 'Tonnellate metriche',
  GT_text: 'Gigaton',
  '/NL_text': 'Per carico',
  '/EA_text': 'Per quantità',
  '/H/ + h_text': "All'ora",
  '/YQ/ + YDQ_text': 'Per metro cubo',
  '/MQ/ + MTQ_text': 'Per metro cubo',
  '/MN/ + MIN_text': 'Al minuto',
  '/MT_text': 'Al metro',
  DSG_text: 'Dosaggio',
  '/C_text': 'Per 100 peso',
  '64_2_text': 'Specifico',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeters}
    one {1 Millimeter}
    other {# Millimeters}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimeters}
    one {1 Centimeter}
    other {# Centimeters}
  }`,
  MTR: `{value, plural,
    =0 {0 Meters}
    one {1 Meter}
    other {# Meters}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometers}
    one {1 Kilometer}
    other {# Kilometers}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimeters}
    one {1 Square Centimeter}
    other {# Square Centimeters}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometers}
    one {1 Square Kilometer}
    other {# Square Kilometers}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimeters}
    one {1 Cubic Millimeter}
    other {# Cubic Millimeters}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimeters}
    one {1 Cubic Centimeter}
    other {# Cubic Centimeters}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimeter}
    one {1 Newton Per Square Millimeter}
    other {# Newtons Per Square Millimeter}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    one {1 Percent}
    other {# Percent}
  }`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    one {1 Degree Celsius}
    other {# Degrees Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    one {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    one {1 Barrel}
    other {# Barrels}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    one {1 Centilitre}
    other {# Centilitres}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    one {1 Decilitre}
    other {# Decilitres}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    one {1 Megagram}
    other {# Megagrams}
  }`,
  SA: `{value, plural,
    =0 {0 Sacks}
    one {1 Sack}
    other {# Sacks}
  }`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    one {1 Percent Weight}
    other {# Percent Weight}
  }`,
  BG: `{value, plural,
    =0 {0 Bags}
    one {1 Bag}
    other {# Bags}
  }`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    one {1 Second}
    other {# Seconds}
  }`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    one {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
  }`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    one {1 Shelf Package}
    other {# Shelf Packages}
  }`,
  MMK: `{value, plural,
    =0 {0 millimetri quadrati}
    one {1 millimetro quadrato}
    other {# millimetri quadrati}
  }`,
  MTN: `{value, plural,
    =0 {0 tonnellate metriche}
    one {1 tonnellata metrica}
    other {# tonnellate metriche}
  }`,
  GT: `{value, plural,
    =0 {0 Gigatoni}
    one {1 Gigaton}
    other {# Gigatoni}
  }`,
  '/NL': `{value, plural,
    =0 {0 per carico}
    one {1 per carico}
    other {# per carico}
  }`,
  '/EA': `{value, plural,
    =0 {0 per quantità}
    one {1 per quantità}
    other {# per quantità}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 all'ora}
    one {1 all'ora}
    other {# all'ora}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 per metro cubo}
    one {1 per metro cubo}
    other {# per metro cubo}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 per metro cubo}
    one {1 per metro cubo}
    other {# per metro cubo}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 al minuto}
    one {1 al minuto}
    other {# al minuto}
  }`,
  '/MT': `{value, plural,
    =0 {0 al metro}
    one {1 al metro}
    other {# al metro}
  }`,
  DSG: `{value, plural,
    =0 {0 dosaggio}
    one {1 dosaggio}
    other {# dosaggio}
  }`,
  '/C': `{value, plural,
    =0 {0 Per 100 di peso}
    one {1 Per 100 di peso}
    other {# Per 100 di peso}
  }`,
  '64_2': `{value, plural,
    =0 {0 Specifica}
    one {1 Specifica}
    other {# Specifica}
  }`,
  mixIdLabel: 'Miscela id/descrizione',
  orderedQtyLabel: 'QTY ordinato',
  loadedQtyLabel: 'Qtà caricata',
  addedByCustomer: 'Aggiunto dal cliente',
  PRINTED: 'Biglietto',
  LOADING_STARTED: 'Inizia a caricare',
  LOADING_COMPLETE: 'Finisci il caricamento',
  TO_JOB: 'Pianta sinistra',
  ARRIVE_JOB: 'Arrivo sito',
  UNLOADING: 'Inizia a scaricare',
  END_UNLOADING: 'Finire lo scarico',
  LEAVE_JOB: 'Lascia lavoro',
  IN_YARD: 'Nel cortile',
  PUMPING_STARTED: 'Avvia la pompa',
  PUMPING_COMPLETE: 'Pompa di finitura',
  READY_TO_PUMP: 'Pronto per pompare',
  FIRST_WCCONTACT: "Primo contatto con cemento d'acqua",
  plantName: 'Nome della pianta',
  plantAddress: "Indirizzo dell'impianto",
  loadingScheduleStart: 'Tempo di caricamento programmato',
  deliveryAddress: 'Indirizzo di consegna',
  phoneNumber: 'Numero di telefono',
  unloadingScheduleStart: 'Tempo di scarico pianificato (ETA)',
  agreedUnloading: 'Tempi di scarico ordinati',
  deliveryInformation: 'Informazioni di consegna',
  next: 'Prossimo',
  material: 'Materiale',
  loadingPoint: 'Punto di caricamento',
  unloadingPoint: 'Punto di scarico',
  comment: 'Commento',
  sapDeliveryNumber: 'Numero di consegna SAP (numero BCC)',
  salesOrderNumber: "Numero dell'ordine di vendita e riga degli articoli",
  wcDateTime: 'Data e ora W/C Contatto',
  po: 'Numero PO',
  orderedByName: 'Ordine effettuato per nome',
  orderedByPhone: 'Ordine effettuato per numero di telefono',
  resourceId: 'ID risorsa',
  totalLoadedQty: 'Qty caricato totale incluso questo carico',
  spacing: 'Spazio di carico',
  deliveryFlow: 'Flusso di consegna',
  timeOnSite: 'Tempo totale in loco/tempo di scarico ordinato',
  salesText: 'Testo di vendita (ID materiale)',
  strengthClass: 'Classe di forza',
  environmentExposure: "Esposizione all'ambiente",
  metExposureClasses: 'Ha incontrato le classi di esposizione',
  dmax: 'Dmax',
  standardCert: 'Standard (certificazione)',
  workabilityTarget: 'Obiettivo di lavorabilità del cliente',
  stoneQuality: 'Qualità delle pietre',
  sandQuality: 'Qualità della sabbia',
  targetWC: 'Target W/C Ratio dalla ricetta',
  controlClass: 'Classe di controllo',
  chlorideClass: 'Classe di cloruro',
  typeAndStrength1: 'Tipo di cemento e classe di forza 1',
  typeAndStrength2: 'Tipo di cemento e classe di forza 2',
  typeOfAddition: 'Tipo di aggiunta',
  typeOfAdmixture: 'Tipo di miscela',
  orderedTemp: 'Temperatura ordinata',
  fibers1: 'Tipo e contenuto delle fibre 1',
  fibers2: 'Tipo e contenuto di fibre 2',
  calculatedMaturityTime: 'Tempo di maturità calcolato dal tempo di contatto W/C',
  mixIdentity: "Mescolare l'identità",
  grade: 'Grado',
  mixType: 'Tipo di mescola',
  maxAggSize: 'Dimensione massima AGG',
  maxAggType: 'Tipo AGG massimo',
  cementType: 'Tipo di cemento',
  admixtures: 'Addixtures',
  addmixDescription: 'Descrizione Addix',
  slump: 'Crollo',
  minCementContent: 'Contenuto di cemento min',
  maxWatCemRatio: 'Rapporto acqua/cemento massimo',
  maxWaterToAdd: 'Max Acqua da aggiungere',
  mortarMinWorkingLife: 'Mortaio min vita lavorativa',
  code: 'Codice',
  dcClass: 'dcclass',
  chloride: 'Cloruro',
  signedByHanson: 'Firmato da Hanson',
  warmStamp: 'Timbro caldo',
  coldStamp: 'Timbro freddo',
  containsSikatard: 'Contiene Sikatard',
  containsDelvo: 'Contiene Delvo',
  generalDelivery: 'Informazioni di consegna generale',
  materialInfo: 'Informazioni sui materiali',
  water: 'Acqua',
  materialService: 'Materiale / servizio',
  returnedMaterial: 'Materiale restituito',
  returnedConcrete: 'Calcestruzzo restituito',
  waterAdded: 'Acqua aggiunta',
  concreteOnTruck: 'Cemento su camion',
  concreteOnTruckUom: 'Concrete predefinito sul camion uom',
  reason: 'Motivo',
  customerAddedWater: 'Cliente ha aggiunto acqua',
  addWater: 'Aggiungere acqua',
  addedAt: 'Aggiunto a',
  addMaterialService: 'Aggiungi materiale/servizio',
  customerAddedProduct: 'Prodotto aggiunto dal cliente',
  addReturnedMaterial: 'Aggiungi materiale restituito',
  leftover: 'Rimanente',
  submitAcceptance: 'Invia accettazione',
  accepted: 'Accettato',
  rejected: 'Rifiutato',
  customerAcceptance: 'Accettazione',
  finalize: 'Finalizzare',
  materialSummary: 'Riepilogo del materiale',
  mix: 'Mescolare',
  ordered: 'Ordinato',
  loaded: 'Carico di consegna',
  totalOrdered: "Quantità totale dell'ordine",
  loadedOrderQuantity: 'Quantità di ordine caricata',
  loadedOrder: 'Ordine caricato',
  driverComment: 'Commento del conducente',
  customerComment: 'Commento del cliente',
  editComment: 'Modifica commento',
  addComment: 'Aggiungi un commento',
  additionalInfo: 'Informazioni addizionali',
  onBoard: 'A bordo',
  driver: 'Autista',
  commentInstruction: 'Istruzione commento',
  signatureInstruction: 'Firma del cliente',
  customerSignature: 'Firma del cliente',
  general: 'Generale',
  status: 'Stato',
  updateStatusTime: 'Aggiorna il tempo di stato',
  delete: 'Eliminare',
  cancel: 'Annulla',
  back: 'Indietro',
  add: 'Aggiungere',
  done: 'Fatto',
  ok: 'OK',
  decline: 'Declino',
  Submit: 'Invia',
  submit: 'Invia',
  Time: 'Tempo',
  clear: 'Chiaro',
  lastRefresh: 'Ultimo aggiornamento',
  refreshEnabled: 'Abilita aggiornamento',
  id: 'ID',
  name: 'Nome',
  invoiceable: 'Invocabile',
  provideSignature: 'Fornire firma',
  ticket: 'Biglietto',
  details: 'Dettagli',
  qrCode: 'QR Code',
  driverAcceptanceReason: 'Motivo',
  driverAcceptance: 'Accettazione del conducente',
  'payment-method': 'Modalità di pagamento',
  'add-payment-method': 'Aggiungi metodo di pagamento',
  'reason-code': 'Codice motivo',
  'add-reason-code': 'Aggiungi codice motivo',
  'qr-code': 'QR Code',
  'unloading-method': 'Metodi di scarico',
  'add-unloading-method': 'Aggiungi metodo di scarico',
  'driver-comment': 'Commenti del conducente',
  'add-driver-comment': 'Aggiungi commento del driver',
  'driver-sellable': 'Autista vendibile',
  vehicle: 'Veicoli',
  'add-vehicle': 'Aggiungi veicolo',
  'vehicle-type': 'Tipi di veicoli',
  vehicleTypes: 'Tipi di veicoli',
  vehicleType: 'tipo di veicolo',
  'add-vehicle-type': 'Aggiungi il tipo di veicolo',
  countries: 'Paesi',
  country: 'Paese',
  type: 'Tipo',
  uom: 'Uom',
  mandatory: 'Obbligatorio',
  qrTemplate: 'Modello di codice QR',
  includeMixInfo: 'Includi le informazioni sulla miscela nel codice QR',
  includeTicketEvents: 'Includi eventi dei biglietti nel codice QR',
  quantityField: 'Abilita il campo di quantità',
  concreteOnTruckField: 'Abilita il calcestruzzo sul campo di camion',
  materialOnTruckField: 'Abilita il campo materiale sul camion',
  timeField: 'Abilita il campo temporale',
  reasonField: 'Abilita il campo Motivo',
  driverInstructions: 'Istruzioni del conducente',
  waitingForTicket: 'Aspettando il biglietto ...',
  createTestTicketForTruck: 'Crea un biglietto per il Test per il camion',
  exceedsLoadedQuantity: 'Il valore non può superare la quantità caricata di {loadedQuantity}.',
  maxQuantity: 'Quantità massima',
  maxQuantityError: 'La quantità non può superare la quantità massima definita di {maxQty}',
  maxValueError: 'Il valore non può superare il massimo definito di {maxQty}',
  minValueError: 'Il valore non può essere inferiore al minimo definito di {minQty}',
  notAuthorized: 'Non autorizzato',
  enterTruckNumber: 'Immettere il numero del camion',
  setTruckNumber: 'Imposta il numero del camion',
  commentList: 'Elenco dei commenti',
  list: 'Elenco',
  gridPreferences: 'Preferenze della griglia',
  loadingUnloadingPoints: 'Punti di caricamento/scarico',
  activity: 'Attività',
  crn: 'Crn',
  update: 'Aggiornamento',
  CONCRETE: 'Calcestruzzo',
  WATER: 'Acqua',
  ADDITIVE: 'Additivo',
  'submit-signature-button': 'Accettare',
  'cancel-signature-button': 'Annulla',
  uomCode: 'Predefinito uom',
  'product-type': 'Tipi di prodotto',
  'add-product-type': 'Aggiungi il tipo di prodotto',
  'add-product': 'Aggiungi prodotto',
  'setup_driver-comment': 'Commenti del conducente',
  setup_product: 'Prodotti',
  'setup_product-type': 'Tipi di prodotto',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Codici motivi',
  'setup_unloading-method': 'Metodi di scarico',
  'setup_entity-mt': 'Azienda',
  setup_vehicle: 'Veicoli',
  'setup_vehicle-type': 'Tipi di veicoli',
  'setup_data-retentions': 'Conservazione dei dati',
  'add-data-retentions': 'Aggiungi la conservazione dei dati',
  'data-retentions': 'Conservazione dei dati',
  setup_carriers: 'Vettori',
  'add-carriers': 'Aggiungi vettori',
  carriers: 'Vettori',
  'setup_concrete-classes': 'Conformità materiale',
  'add-concrete-classes': 'Aggiungi la conformità materiale',
  'concrete-classes': 'Conformità materiale',
  'setup_forms-and-waivers': 'Forme e esenzioni',
  'add-forms-and-waivers': 'Aggiungi moduli e esenzioni',
  'forms-and-waivers': 'Forme e esenzioni',
  companyFleet: 'Flotta aziendale',
  category: 'Categoria',
  interval: 'Intervallo',
  orders: 'Ordini e biglietti',
  '30days': '30 giorni',
  '60days': '60 giorni',
  '90days': '90 giorni',
  '1year': '1 anno',
  '3years': '3 anni',
  waterMandatory: 'Acqua obbligatoria',
  qrCodeTemplate: 'Modello di codice QR',
  waterConfig: 'Campi opzionali',
  waterProduct: 'Acqua',
  waterProductType: "Tipo di prodotto d'acqua",
  defaultWaterProduct: 'Prodotto idrico predefinito',
  invalidTemplate: 'Modello non valido',
  siteAddress: 'Indirizzo del sito',
  siteContactName: 'Nome contatto del sito',
  siteContactPhoneNumber: 'Numero di telefono di contatto del sito',
  'clear-signature-button': 'Chiara firma',
  customerCommentEnabled: 'Abilita il commento del cliente',
  driverCommentEnabled: 'Abilita il commento del conducente',
  driverCommentMaxCharacters: 'Lunghezza massima',
  customerCommentMaxCharacters: 'Lunghezza massima',
  timeZone: 'Fuso orario',
  driverDidNotAdd: 'Il driver non ha aggiunto',
  maxLineItems: 'Numero massimo di elementi di riga',
  oneLineItem: 'Deve avere almeno 1 elemento di riga',
  signatureSiteContact: 'Nome del cliente',
  ticketEvents: 'Eventi del biglietto',
  hideEvent: 'Nascondi evento',
  editable: 'Modificabile',
  ticketDetail: 'Biglietto #{value}',
  statusTimes: 'Tempi di stato',
  materialAndDeliveryInfo: 'Informazioni sul materiale e sulla consegna',
  customerRejection: 'Rifiuto del cliente',
  'submit-rejection-button': 'Invia il rifiuto',
  accept: 'Accettare',
  reject: 'Rifiutare',
  signature: 'Firma',
  'no-signature': 'Nessuna firma',
  unFinalizePassword: 'Unimalizzare la password',
  waterInWorkflow: "Abilita il gradino dell'acqua nel flusso di lavoro",
  multicountry: 'Multipaese',
  timestampAfterSequence: 'ATTENZIONE: il tempo non dovrebbe essere prima del timestamp precedente',
  timestampBeforeSequence: 'ATTENZIONE: il tempo non dovrebbe essere dopo il prossimo timestamp',
  noRestQuantity: 'Nessuna quantità di riposo',
  returnedMaterialMandatory: 'Deve interagire con il passaggio del materiale restituito',
  driverCommentMaxChars: 'La lunghezza non può superare il massimo di {maxLength} caratteri.',
  QrCodeEnabled: 'Abilita il codice QR',
  QrMethod_linkBased: 'Basato su collegamenti',
  QrMethod_contentBased:
    'Basato sui contenuti, NOTA: i codici QR basati sul contenuto presentano limitazioni di caratteri che potrebbero causare problemi oltre i 1024 caratteri.',
  QrMethod_contentBasedWarning:
    'NOTA: questo codice QR contiene {amount} caratteri che potrebbero non essere leggibili da alcuni telefoni.',
  returnedMaterialInWorkflow: 'Abilita il passo del materiale restituito nel flusso di lavoro',
  materialServiceInWorkflow: 'Abilita Materiale/Servizio Step nel flusso di lavoro',
  totalPumpedQuantity: 'Quantità pompata totale',
  concrete: 'Calcestruzzo',
  materialComplianceEnabled: 'Abilita la conformità materiale',
  pumpStatusTimes: 'Tempi di stato della pompa',
  pollingInterval: 'Intervallo di sondaggi (secondi)',
  tenSecs: "L'intervallo deve essere di almeno 10 secondi",
  materialCompliance: 'Conformità materiale',
  consistenceValue: 'Consistenza',
  consistenceIsCompliant: 'Coerenza è conforme a BS8500-2',
  airContentValue: "Contenuto d'aria",
  airContentIsCompliant: 'Il contenuto aereo è conforme a BS8500-2',
  consistenceIsCompliantShort: 'Coerenza è conforme',
  airContentIsCompliantShort: 'Il contenuto aereo è conforme',
  compliance: 'Conformità materiale a BS8500-2',
  termsAndConditionsEnabled: 'Abilita i termini e le condizioni',
  termsAndConditions: 'Termini e Condizioni',
  damageWaiverEnabled: 'Abilitare la forma di rinuncia al danno',
  damageWaiver: 'Rinuncia al danno',
  damageWaiverAcknowledged: 'Riconosciuta la rinuncia al danno',
  dynamicWaiver: 'Rinuncia dinamica',
  dynamicWaiverEnabled: 'Abilita forma di esonero dinamica',
  ticketListEnabled: 'Abilita la lista dei biglietti',
  clearTicketAfterSubmission: "Biglietto chiaro dopo l'invio",
  keepTicketOpenAfterSubmission: "Mantieni aperto i biglietti dopo l'invio",
  displayTicketList: "Visualizza l'elenco dei biglietti",
  supportedLanguages: 'Lingue supportate',
  allow: 'Permettere',
  default: 'Predefinito',
  translations: 'Traduzioni',
  family_name: 'Cognome',
  given_name: 'Nome di battesimo',
  email: 'E-mail',
  role: 'Ruolo',
  inviteUser: "Invita l'utente",
  carrier: 'Vettore',
  emailOrPhoneNumber: 'E-mail',
  usersEditor: 'Editor per utenti',
  verifyPassword: 'Verifica la password',
  verificationCode: 'Codice di verifica',
  register: 'Registrati',
  registerInstructions: 'Compila questo modulo per finalizzare la registrazione.',
  'password-requirements':
    'La password deve essere almeno 8 caratteri, contenere almeno 1 maiuscolo, 1 minuscolo, 1 numero e 1 carattere speciale.',
  passwordMinCharacters: 'La password deve contenere 8 o più caratteri.',
  passwordMaxCharacters: 'La password non può contenere più di 99 caratteri.',
  passwordLowerCase: 'La password deve includere 1 o più lettera minuscola.',
  passwordUpperCase: 'La password deve includere 1 o più lettera maiuscolo.',
  passwordNumber: 'La password deve includere 1 o più carattere numerico.',
  passwordSpecialCharacter: 'La password deve includere 1 o più carattere speciale.',
  passwordsDontMatch: 'Le password non corrispondono!',
  success: 'Successo!',
  'registration-success': 'La tua registrazione ha avuto successo.',
  'post-registration-instructions': 'Hai completato il processo di registrazione. Ora puoi accedere.',
  createDate: 'Crea data',
  customerPo: 'Numero PO del cliente',
  specification: 'Specifiche',
  description: 'Descrizione',
  classType: 'Tipo di classe',
  minimumValue: 'Valore minimo',
  maximumValue: 'Valore massimo',
  TrackIt: 'Trackit',
  trackIt3PContext: 'Contesto di tracciat 3p',
  inviteLanguage: 'Invita il linguaggio',
  truckNumber: 'Numero di camion',
  adverseTemperature:
    'La temperatura ambiente al momento della miscelazione era tale che non possiamo garantire che il calcestruzzo sarà nelle variazioni di temperatura di 5-35OC come specificato in EN 206.',
  environmentalNote:
    "Questo carico contiene un agente ambientale che non supera lo 0,03% in peso del calcestruzzo. L'acqua contenente questo materiale per la produzione di cemento rispetterà BS EN 1008.",
  unloadingMethodEnabled: 'Abilita metodo di scarico',
  warrantyVoided: 'Garanzia materiale annullata',
  isCertificationLossTriggerForReason: 'Causa la perdita di certificazione',
  isCertificationLossTriggerForProduct: 'Causa la perdita di certificazione',
  materialWarrantyVoidedEnabled:
    'Quando si utilizza il prodotto per la perdita di certificazione o il codice prodotto & motivo, mostrare una nota di perdita di certificazione al momento della firma.',
  'setup_templates-mt': 'Modelli',
  copyFrom: 'Copia da...',
  copy: 'copia',
  Copy: 'copia',
  users: 'Utenti',
  setup: 'Impostare',
  driverExperience: 'Esperienza del conducente',
  ticketList: 'Elenco dei biglietti',
  content: 'Contenuto',
  WARNINGS: 'Avvertimenti',
  TERMS: 'Termini',
  DAMAGE: 'Danno',
  ADDITIONALWAIVER: 'Rinuncia aggiuntiva',
  DYNAMICWAIVER: 'Rinuncia dinamica',
  source: 'Fonte',
  sensorUnable: 'Sensore incapace di determinare la quantità rimanente.',
  PROBE: 'Sonda',
  DRIVER: 'Autista',
  DRS: 'Drs',
  waterReleaseFormEnabled: "Abilita forma di rilascio dell'acqua",
  waterRelease: "Rilascio d'acqua",
  waterReleaseAcknowledged: `Riconosciuto il rilascio dell'acqua`,
  'assessment-risk': 'Valutazione del rischio',
  'setup_assessment-risk': 'Valutazione del rischio',
  'add-assessment-risk': 'Aggiungi valutazione del rischio',
  enterRecipientsButton: 'Inserisci il/i destinatario/i',
  recipientsInstructions: `Inserisci l'e-mail, separato da una virgola`,
  enter: 'accedere',
  invalidEmailAddress: 'indirizzo email non valido',
  personalDataPolicy:
    'Inserendo i dati personali in questo modulo, dichiari di disporre delle autorizzazioni necessarie per condividere tali dati personali',
  subcategory: 'Sottocategoria',
  isRequired: 'È obbligatorio',
  riskAssessmentEnabled: 'Abilita la valutazione del rischio',
  riskAssessment: 'Valutazione del rischio',
  yes: 'SÌ',
  no: 'NO',
  sourceOptionsSensors: 'Opzioni di origine (sensori)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonda',
  availableCustomValues: 'Valori personalizzati disponibili',
  ERR_CODE__DUPLICATE: 'Duplicare',
  ERR_CODE__DEFAULT_VALUE: 'Esiste già un valore predefinito',
  ERR_CODE__EXISTING_FORM: 'Esiste già un modulo attivo per questo Veicolo e Tipo',
  availableValues: 'Valori disponibili',
  pump: 'Pompa',
  DanskName: 'Nome Dansk',
  GermanName: 'Nome tedesco',
  EnglishName: 'Nome inglese',
  BritainName: 'Nome Gran Bretagna',
  SpanishName: 'Nome spagnolo',
  FrenchName: 'Nome francese',
  NederlandsName: 'Nome Nederlands',
  NorskName: 'Nome Norsk',
  SvenskaName: 'Nome Svenska',
  ItalianName: 'Nome italiano',
  PolishName: 'Nome polacco',
  DanskNameDefault: 'Nome Dansk (impostazione predefinita)',
  GermanNameDefault: 'Nome tedesco (impostazione predefinita)',
  EnglishNameDefault: 'Nome inglese (impostazione predefinita)',
  BritainNameDefault: 'Nome Gran Bretagna (impostazione predefinita)',
  SpanishNameDefault: 'Nome spagnolo (impostazione predefinita)',
  FrenchNameDefault: 'Nome francese (impostazione predefinita)',
  NederlandsNameDefault: 'Nome Nederlands (impostazione predefinita)',
  NorskNameDefault: 'Nome Norsk (impostazione predefinita)',
  SvenskaNameDefault: 'Nome Svenska (impostazione predefinita)',
  ItalianNameDefault: 'Nome italiano (impostazione predefinita)',
  PolishNameDefault: 'Nome polacco (impostazione predefinita)',
  DanskDescription: 'Descrizione di Dansk',
  GermanDescription: 'Descrizione tedesca',
  EnglishDescription: 'Descrizione inglese',
  BritainDescription: 'Descrizione della Gran Bretagna',
  SpanishDescription: 'Descrizione spagnola',
  FrenchDescription: 'Descrizione francese',
  NederlandsDescription: 'Descrizione di Nederlands',
  NorskDescription: 'Descrizione Norsk',
  SvenskaDescription: 'Descrizione Svenska',
  ItalianDescription: 'Descrizione italiano',
  PolishDescription: 'Descrizione polacca',
  DanskDescriptionDefault: 'Descrizione Dansk (impostazione predefinita)',
  GermanDescriptionDefault: 'Descrizione tedesca (impostazione predefinita)',
  EnglishDescriptionDefault: 'Descrizione inglese (impostazione predefinita)',
  BritainDescriptionDefault: 'Descrizione della Gran Bretagna (impostazione predefinita)',
  SpanishDescriptionDefault: 'Descrizione spagnola (impostazione predefinita)',
  FrenchDescriptionDefault: 'Descrizione francese (impostazione predefinita)',
  NederlandsDescriptionDefault: 'Descrizione Nederlands (impostazione predefinita)',
  NorskDescriptionDefault: 'Descrizione Norsk (impostazione predefinita)',
  SvenskaDescriptionDefault: 'Descrizione Svenska (impostazione predefinita)',
  ItalianDescriptionDefault: 'Descrizione italiano (impostazione predefinita)',
  PolishDescriptionDefault: 'Descrizione polacca (impostazione predefinita)',
  DanskContent: 'Contenuto di Dansk',
  GermanContent: 'Contenuto tedesco',
  EnglishContent: 'Contenuto inglese',
  BritainContent: 'Contenuto della Gran Bretagna',
  SpanishContent: 'Contenuto spagnolo',
  FrenchContent: 'Contenuto francese',
  NederlandsContent: 'Contenuto di Nederlands',
  NorskContent: 'Contenuto Norsk',
  SvenskaContent: 'Contenuto di Svenska',
  ItalianContent: 'Contenuto di italiano',
  PolishContent: 'Contenuto polacco',
  DanskContentDefault: 'Contenuto di Dansk (impostazione predefinita)',
  GermanContentDefault: 'Contenuto tedesco (impostazione predefinita)',
  EnglishContentDefault: 'Contenuto inglese (impostazione predefinita)',
  BritainContentDefault: 'Contenuto della Gran Bretagna (impostazione predefinita)',
  SpanishContentDefault: 'Contenuto spagnolo (impostazione predefinita)',
  FrenchContentDefault: 'Contenuti francesi (impostazione predefinita)',
  NederlandsContentDefault: 'Contenuto di Nederlands (impostazione predefinita)',
  NorskContentDefault: 'Contenuto Norsk (impostazione predefinita)',
  SvenskaContentDefault: 'Contenuto Svenska (impostazione predefinita)',
  ItalianContentDefault: 'Contenuto italiano (impostazione predefinita)',
  PolishContentDefault: 'Contenuto polacco (impostazione predefinita)',
  DanskCategory: 'Categoria Dansk',
  GermanCategory: 'Categoria tedesca',
  EnglishCategory: 'Categoria inglese',
  BritainCategory: 'Categoria Gran Bretagna',
  SpanishCategory: 'Categoria spagnola',
  FrenchCategory: 'Categoria francese',
  NederlandsCategory: 'Categoria Nederlands',
  NorskCategory: 'Categoria Norsk',
  SvenskaCategory: 'Categoria Svenska',
  ItalianCategory: 'Categoria italiano',
  PolishCategory: 'Categoria polacca',
  DanskCategoryDefault: 'Categoria Dansk (impostazione predefinita)',
  GermanCategoryDefault: 'Categoria tedesca (impostazione predefinita)',
  EnglishCategoryDefault: 'Categoria inglese (impostazione predefinita)',
  BritainCategoryDefault: 'Categoria britannica (impostazione predefinita)',
  SpanishCategoryDefault: 'Categoria spagnola (impostazione predefinita)',
  FrenchCategoryDefault: 'Categoria francese (impostazione predefinita)',
  NederlandsCategoryDefault: 'Categoria Nederlands (impostazione predefinita)',
  NorskCategoryDefault: 'Categoria Norsk (impostazione predefinita)',
  SvenskaCategoryDefault: 'Categoria Svenska (impostazione predefinita)',
  ItalianCategoryDefault: 'Categoria italiano (impostazione predefinita)',
  PolishCategoryDefault: 'Categoria polacca (impostazione predefinita)',
  DanskSubcategory: 'Sottocategoria Dansk',
  GermanSubcategory: 'Sottocategoria tedesca',
  EnglishSubcategory: 'Sottocategoria inglese',
  BritainSubcategory: 'Sottocategoria britannica',
  SpanishSubcategory: 'Sottocategoria spagnola',
  FrenchSubcategory: 'Sottocategoria francese',
  NederlandsSubcategory: 'Sottocategoria Nederlands',
  NorskSubcategory: 'Sottocategoria Norsk',
  SvenskaSubcategory: 'Sottocategoria Svenska',
  ItalianSubcategory: 'Sottocategoria italiano',
  PolishSubcategory: 'Sottocategoria polacca',
  DanskSubcategoryDefault: 'Sottocategoria Dansk (impostazione predefinita)',
  GermanSubcategoryDefault: 'Sottocategoria tedesca (impostazione predefinita)',
  EnglishSubcategoryDefault: 'Sottocategoria inglese (impostazione predefinita)',
  BritainSubcategoryDefault: 'Britain Subccategoria (impostazione predefinita)',
  SpanishSubcategoryDefault: 'Sottocategoria spagnola (impostazione predefinita)',
  FrenchSubcategoryDefault: 'Sottocategoria francese (impostazione predefinita)',
  NederlandsSubcategoryDefault: 'Sottocategoria Nederlands (impostazione predefinita)',
  NorskSubcategoryDefault: 'Sottocategoria Norsk (impostazione predefinita)',
  SvenskaSubcategoryDefault: 'Sottocategoria Svenska (impostazione predefinita)',
  ItalianSubcategoryDefault: 'Sottocategoria italiano (impostazione predefinita)',
  PolishSubcategoryDefault: 'Sottocategoria polacca (impostazione predefinita)',
  priorSignatures: 'Firme precedenti',
  damageWaiverSignature: 'Rinuncia al danno Firma',
  waterReleaseSignature: "Firma del rilascio dell'acqua",
  errors,
  themes,
  isPDFRecipientListEnable: "Liste des destinataires PDF activée sur l'écran de signature du client",
  isRejectLoadsEnable: `Abilita il rifiuto dei carichi dopo l'arrivo al sito`,
  rejectLoad_returnedMaterial: 'Pulsante del flusso di lavoro del materiale restituito',
  rejectLoad_returnedAndFinalize: 'Pulsanti del flusso di lavoro Materiale restituito e Finalizzazione',
  driverRejection: 'Rifiuta carico',
  submitRejection: 'Invia Rifiuta',

  costAndPaymentsSummary: 'Riepilogo costi e pagamenti',
  subtotal: 'totale parziale',
  tax: 'Imposta',
  remainingBalance: 'Equilibrio restante',
  paymentAmount: 'Importo del pagamento',
  total: 'Totale',
  paymentMethod: 'Metodo di pagamento',
  paymentProducts: 'Prodotti',
  unitPrice: 'Prezzo unitario',
  amount: 'Quantità',
  otherPaymentMethod: 'Altro metodo di pagamento',
  method: 'Metodo',
  paymentOptions: 'Opzioni di pagamento',
  typeAdditionalInfo: 'Digita qui ulteriori informazioni',
  COD: 'Pagamento alla consegna',
  AMEX: 'American Express',
  CASH: 'Contanti',
  CHECK: 'Controllo',
  'CREDIT CARD': 'Carta di credito',
  ACCOUNT: 'Account aperto',
  UNKNOWN: 'Sconosciuto',
  remainingMaterial: 'Materiale rimanente',
  splitLoad: 'Carico diviso',
  isEnablePaymentAndCostWorkflows: 'Abilita i flussi di lavoro di pagamento e costo',
  airTempValue: "Temperatura dell'Aria",
  concreteTempValue: 'Temperatura del Calcestruzzo',
  cylinderNumber: 'Numero del cilindro',
  materialOnTruck: 'Materiale sul camion',
  waterLocation: "Posizione dell'acqua",
  dotWorkflow: 'Flusso di lavoro DOT',
  inspectorSignature: "Firma dell'ispettore",
  producerSignature: 'Firma del produttore',
  addedItems: 'Elementi aggiunti',
  producerSignatureContact: 'Nome del produttore',
  inspectorSignatureContact: "Nome dell'ispettore",
  waterAtPlant: 'Acqua in impianto (gl)',
  waterAtJobsite: 'Acqua sul cantiere (gl)',
  totalActualWater: 'Totale acqua effettiva',
  timeDischarged: 'Tempo di scarico',
  ingredient: "Ingrediente",
  mcFac: "MCFac",
  absFac: "ABSFAC",
  od: "OD",
  abs: "ABS",
  ssd: "SSD",
  freeMst: "Umidità Libera",
  cydsTarg: "CYDS Obiettivo",
  target: "Obiettivo",
  actual: "Reale",
  freWat: "Acqua Libera",
  trim: "Rifilo",
  batchInformation: "Informazioni del Lotto",
  numberOfBatches: "Numero di Lotti",
  totalWater: "Acqua Totale",
  designWater: "Acqua di Progetto",
  toAdd: "Da Aggiungere",
  designWC: "Progetto W/C",
  waterCement: "Acqua / Cemento",
  waterInTruck: "Acqua nel Camion",
  adjustWater: "Regola Acqua",
  trimWater: "Rifila Acqua",
  plantWeather: "Tempo alla Pianta",
  rh: "RH",
  observationTime: "Tempo di Osservazione",
  address: "Indirizzo",
  plant: "Pianta",
  sp: "S.P. #",
  beginTime: "Ora di Inizio",
  mixDesc: "Descrizione del Mix",
  qtyOder: "Quantità Ordine",
  poTag: "PO #",
  bridge: "Ponte #",
  volume: "Volume",
  truckTag: "Camion #",
  qtyDelivered: "Quantità Consegnata",
  qtyOrder: "Quantità Ordine",
  job: "Lavoro #",
  batchRecord: "Registrazione del Lotto",
  mixID: "ID del Mix",
  mixDesc: "Descrizione del Mix",
  loads: "Carichi",
  deliveryInformation: "Informazioni di Consegna",
  isDot: "È DOT",
  tolStatus: "Stato TOL",
  materialSummary: 'Riepilogo Materiale',
};
