import { Form, Localization } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();

  if (!values?.returnReason && !values?.driverDidNotAdd) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>
        <Localization.Translate stringId="returnedMaterial" data-testid="returnedMaterial-label" />
      </td>
      <td></td>
      <td data-testid={`${testId}-concrete-value`}>
        <Localization.Uom
          uom={
            values?.returnConcreteOnTruck?.value
              ? values.returnConcreteOnTruck
              : { value: '0', uomCode: values?.returnConcreteOnTruck.uomCode }
          }
        />
      </td>
      <td data-testid={`${testId}-reason-value`}>
        {(values?.returnConcreteOnTruck?.value && values?.returnReason?.description) || ''}
      </td>
    </tr>
  );
};
