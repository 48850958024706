import { find } from 'lodash';

export const activeFormsAndWaiversArray = ({
  ticket,
  formTypeCondition,
  formsAndWaiversSetup,
  vehicleSetup,
  companySetup,
  translateObjects,
  userLocale,
}) => {
  const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
  const vehicleType = targetVehicle?.vehicleTypes;

  if (!formsAndWaiversSetup?.data) {
    return [];
  }

  const formsAndWaivers = formsAndWaiversSetup.data
    ?.filter?.(comment => comment.status === 'ACTIVE')
    ?.filter?.(comment => comment.type === formTypeCondition)
    ?.filter?.(comment =>
      companySetup?.data?.isMulticountry === true
        ? comment?.countries?.includes(ticket?.origin?.address?.countryCode) || !comment?.countries?.length
        : comment
    )
    ?.filter?.(comment => comment?.vehicleTypes === vehicleType || !comment?.vehicleTypes?.length);

  if (!translateObjects) {
    return formsAndWaivers;
  }

  const formsAndWaiversTranslated = translateObjects(formsAndWaivers, {
    getStringId: crn => `SETUP_FORMS-AND-WAIVERS_${crn}`?.replace(/\s/gi, '_')?.toUpperCase(),
    getPath: 'crn',
    setPath: 'label',
    defaultMessagePath: 'content',
  });

  if (!formsAndWaiversTranslated) {
    return formsAndWaivers;
  }

  if (formsAndWaiversTranslated?.every(element => element?.label === element?.content)) {
    return formsAndWaiversTranslated?.map(element => ({
      ...element,
      label: element?.i18n[userLocale] ?? element?.content,
    }));
  }

  return formsAndWaiversTranslated;
};
