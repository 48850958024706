import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization, Core, Form } from 'connex-cds';

import style, { StyledBodyMobile, StyledFooter, StyledHeader, StyledTable, StyledDiv } from './Style';
import { SignaturesSection } from './SignaturesSection';

const { Translate } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'addedItemsGrid';

export const AddedItemsMobile = ( {productCompliance} ) => {
  const { airContentValue, airTempValue, concreteTempValue, cylinderNumber, slump } = productCompliance;
  const translateMessage = Localization.useTranslateMessage();
  return (
    <Styled data-testid={testId} className={cn('added-items-section')}>
      <StyledTable className={cn('added-items-table')}>
        <StyledHeader>
          <div className={cn('added-items-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="addedItems" data-testid="added-items-label" />
          </div>
        </StyledHeader>
        <StyledBodyMobile>  
        <StyledDiv>
            {
             1 == 3 ? (
                <>
                  <span><Translate stringId="waterAtPlant"/></span>
                  {airTempValue.value}
                </>
              ) : translateMessage('waterAtPlant')
            }
          </StyledDiv>
          <StyledDiv>
            {
             1 == 3 ? (
                <>
                  <span><Translate stringId="waterAtJobsite"/></span>
                  {airTempValue.value}
                </>
              ) : translateMessage('waterAtJobsite')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              airContentValue?.value ? (
                <>
                  <span><Translate stringId="airContentValue"/></span>
                  {airContentValue.value}
                </>
              ) : translateMessage('airContentValue')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              airTempValue?.value ? (
                <>
                  <span><Translate stringId="airTempValue"/></span>
                  {airTempValue.value}
                </>
              ) : translateMessage('airTempValue')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              concreteTempValue?.value ? (
                <>
                  <span><Translate stringId="concreteTempValue"/></span>
                  {concreteTempValue.value}
                </>
              ) : translateMessage('concreteTempValue')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              1 == 3 ? (
                <>
                  <span><Translate stringId="slump"/></span>
                  {concreteTempValue.value}
                </>
              ) : translateMessage('slump')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              cylinderNumber ? (
                <>
                  <span><Translate stringId="cylinderNumber"/></span>
                  {cylinderNumber}
                </>
              ) : translateMessage('cylinderNumber')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              1 == 3 ? (
                <>
                  <span><Translate stringId="timeDischarged"/></span>
                  {cylinderNumber.value}
                </>
              ) : translateMessage('timeDischarged')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              1 == 3 ? (
                <>
                  <span><Translate stringId="totlaActualWater"/></span>
                  {cylinderNumber}
                </>
              ) : translateMessage('totlaActualWater')
            }
          </StyledDiv>
          <StyledDiv style={{ display: 'none'}}>
            {
              1 == 3 ? (
                <>
                  <span><Translate stringId="location"/></span>
                  {cylinderNumber}
                </>
              ) : translateMessage('location')
            }
          </StyledDiv>
        </StyledBodyMobile>
        <StyledFooter>
          <SignaturesSection />
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
