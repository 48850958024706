import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Core, Form, Drawer, Localization } from 'connex-cds';
import { useTicketContext } from '../../../../TicketContext';
import { useCompanySetup, useFormsAndWaiversSetup, useVehicleSetup } from '../../../../MasterDataProvider';
import { activeFormsAndWaiversArray } from '../../../../getActiveForms';
import style from './style';

const { Column, Row } = Layout;

const Styled = styled.div`
  ${style}
`;

export const DynamicWaiverModal = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { userLocale } = Localization.useLocalizationContext();
  const { ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const {
    Components: { DynamicWaiverSignature },
    setFieldValue,
    values,
  } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [signature, setSignature] = useState(false);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'DYNAMICWAIVER',
        formsAndWaiversSetup,
        vehicleSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [companySetup, formsAndWaiversSetup, ticket, vehicleSetup, translateObjects, userLocale]
  );

  const disableSubmitSignature = React.useMemo(() => {
    return !values?.dynamicWaiverSignature?.image;
  }, [values?.dynamicWaiverSignature]);

  const handleDeclineDynamicWaiver = React.useCallback(() => {
    setFieldValue('dynamicWaiverSignature', undefined);
    setFieldValue('dynamicWaiverDeclined', true);
    closeDrawer();
  }, [setFieldValue]);

  const handleAcceptDynamicWaiver = React.useCallback(() => {
    setSignature(true);
  }, []);

  const handleAcceptCancelSignature = React.useCallback(() => {
    setFieldValue('dynamicWaiverSignature', undefined);
    setFieldValue('dynamicWaiverDeclined', false);
    setSignature(false);
  }, [setFieldValue]);

  const handleSubmitSignature = React.useCallback(() => {
    setFieldValue('dynamicWaiverDeclined', false);
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Styled className={cn('dynamic-waiver')}>
      <Core.Spinner spin={vehicleSetup.isLoading || formsAndWaiversSetup.isLoading || companySetup.isLoading}>
        {!signature ? (
          <Column>
            <div data-testid="dynamicWaiver">
              <pre className={'dynamic-waiver-value'}>
                {activeFormsAndWaivers[0]?.label ?? activeFormsAndWaivers[0]?.content}
              </pre>
            </div>
            <Row>
              <Core.Button
                type="primary"
                stringId="accept"
                onClick={handleAcceptDynamicWaiver}
                data-testid="accept-button"
              />
              <Core.Button
                type="primary"
                stringId="decline"
                onClick={handleDeclineDynamicWaiver}
                data-testid="decline-button"
              />
            </Row>
          </Column>
        ) : (
          <div>
            <DynamicWaiverSignature edit ticketId={ticket?.ticketId} />
            <Row style={{ float: 'right', padding: '20px 0' }}>
              <Core.Button
                onClick={handleAcceptCancelSignature}
                stringId="cancel-signature-button"
                data-testid="cancel-signature-button"
              />
              <Core.Button
                type="primary"
                disabled={disableSubmitSignature}
                onClick={handleSubmitSignature}
                stringId="submit-signature-button"
                data-testid="ok-button"
              />
            </Row>
          </div>
        )}
      </Core.Spinner>
    </Styled>
  );
};
