import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const { ShortDateTime, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled(Layout.Container)``;

const LoadingPointMobile = ({ className, ticket }) => {
  return (
    <Styled className={cn('loading-point', className)}>
      <Subtitle>
        <Translate stringId="loadingPoint" data-testid="loadingPointLabel" />
      </Subtitle>
      <div className="label">
        <Translate stringId="plantName" data-testid="plantNameLabel" />:{' '}
      </div>
      <span data-testid="plantNameValue">{ticket?.origin?.description}</span>
      <div className="label">
        <Translate stringId="plantAddress" data-testid="plantAddressLabel" />:{' '}
      </div>
      <span data-testid="plantAddressValue">{ticket?.origin?.address.address1}</span>
      <div className="label">
        <Translate stringId="loadingScheduleStart" data-testid="loadingScheduleStartLabel" />:{' '}
      </div>
      <span style={{ whiteSpace: 'nowrap' }} data-testid="loadingScheduleStart">
        <ShortDateTime date={ticket?.customData?.schedule?.pickupTimeFrom} withDayOfWeek />
      </span>
    </Styled>
  );
};

export default LoadingPointMobile;
