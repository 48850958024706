import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../../TicketContext';
import { FLOW } from '../Acceptance';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const HeaderMobile = ({ flow, submitAcceptanceVisible, signed, entitySupportedLanguages, errors }) => {
  const {
    Components: { SubmitButton },
  } = Form.useFormContext();

  const { submitted, ticket } = useTicketContext();

  return (
    <Styled className={cn('header')}>
      <div className="left-side">
        {signed ? (
          <Localization.LocaleSwitcher persist={false} entitySupportedLanguages={entitySupportedLanguages} />
        ) : null}
      </div>
      <div className="right-side">
        {signed ? null : (
          <Localization.LocaleSwitcher persist={false} entitySupportedLanguages={entitySupportedLanguages} />
        )}
        {flow === FLOW.ACCEPT_REJECT || (flow === FLOW.SIGNATURE_NO_SIGNATURE && submitAcceptanceVisible) ? (
          <SubmitButton
            stringId={
              ticket?.customerStatus?.statusCode === 'REJECTED'
                ? 'rejected'
                : submitted
                ? 'accepted'
                : 'submitAcceptance'
            }
            data-testid="submit-acceptance-button"
            size="small"
            disabledWhenNotDirty={false}
            disabled={submitted || !isEmpty(errors)}
          />
        ) : null}
      </div>
    </Styled>
  );
};
