import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';
import { useCompanySetup, useMaterialComplianceSetup } from '../../../MasterDataProvider';

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const MaterialComplianceDesktop = () => {
  const {
    Components: {
      ConsistenceValue,
      ConsistenceIsCompliant,
      AirContentValue,
      AirContentIsCompliant,
      DateTest,
      AirTempValue,
      ConcreteTempValue,
      CylinderNumber,
      AdditionalInfo,
    },
    values,
    setFieldValue,
  } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const [airUom, setAirUom] = React.useState();
  const [consistenceUom, setConsistenceUom] = React.useState();
  const [airTemp, setAirTemp] = React.useState();
  const [concreteTemp, setConcreteTemp] = React.useState();

  const materialComplianceSetup = useMaterialComplianceSetup();

  const airCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'AIR') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const consistenceCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'CONSISTENCE') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const airTempCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'AIR_TEMP') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const concreteTempCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'CONCRETE_TEMP') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const isDotEnabled = React.useMemo(() => {
    if (companySetup?.data?.isDotEnabled) {
      return companySetup?.data?.isDotEnabled;
    }

    return false;
  }, [companySetup?.data]);

  React.useEffect(() => {
    const airUomId = airCompliance?.[0]?.uomCode;
    setAirUom(airUomId);
  }, [airCompliance]);

  React.useEffect(() => {
    const consistenceUomId = consistenceCompliance?.[0]?.uomCode;
    setConsistenceUom(consistenceUomId);
  }, [consistenceCompliance]);

  React.useEffect(() => {
    const airTempUomId = airTempCompliance?.[0]?.uomCode;
    setAirTemp(airTempUomId);
  }, [airTempCompliance]);

  React.useEffect(() => {
    const concreteTempUomId = concreteTempCompliance?.[0]?.uomCode;
    setConcreteTemp(concreteTempUomId);
  }, [concreteTempCompliance]);

  const consistenceDisabled = React.useMemo(() => {
    return !values?.productCompliance?.consistenceValue?.value;
  }, [values?.productCompliance?.consistenceValue]);

  const airDisabled = React.useMemo(() => {
    return !values?.productCompliance?.airContentValue?.value;
  }, [values?.productCompliance?.airContentValue]);

  React.useEffect(() => {
    if (!consistenceDisabled || !airDisabled) {
      setFieldValue('productCompliance.dateTest', new Date().toISOString());
    }
  }, [values?.productCompliance?.airContentValue, values?.productCompliance?.consistenceValue]);

  return (
    <Styled className={cn('material-compliance')}>
      <Container>
        <Column>
          <Row>
            <Column>
              <ConsistenceValue uomCode={consistenceUom} />
            </Column>
          </Row>
          <Row>
            <Column>
              <ConsistenceIsCompliant disabled={consistenceDisabled} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AirContentValue uomCode={airUom} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AirContentIsCompliant disabled={airDisabled} />
            </Column>
          </Row>
          {isDotEnabled && (
            <>
              <Row>
                <Column>
                  <AirTempValue uomCode={airTemp} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <ConcreteTempValue uomCode={concreteTemp} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <CylinderNumber />
                </Column>
              </Row>
              <Row>
                <Column>
                  <AdditionalInfo rows={3} validationText={false} />
                </Column>
              </Row>
            </>
          )}
          <Row>
            <Column>
              <DateTest />
            </Column>
          </Row>
        </Column>
      </Container>
    </Styled>
  );
};
