import { css } from 'styled-components';

export default css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &&& {
    .overview {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #1890ff;
        position: relative;
        cursor: pointer;
        padding: 0 10px;
        transition: background-color 0.3s;
        &.active {
          color: #1890ff;
          .label {
            text-decoration: underline;
          }
          .icon {
          }
        }

        .label {
          font-weight: 500;
        }

        &:not(.active) {
          &:not(.disabled) {
            &.ok {
              color: #187c18;
            }

            &.mandatory {
              color: #bb9a1c;
            }

            &.error {
              color: red;
            }

            &.default {
              color: #aaa;
            }
          }
        }

        &.disabled {
          cursor: not-allowed;
          color: #aaa;
          .label {
            font-style: italic;
            font-weight: 300;
          }
        }

        &.loading {
          cursor: wait;
        }

        .icon {
          height: 35px;
          font-size: 1.5em;
          display: flex;
          align-items: center;
          background-color: var(--color-background-page);
          transition: all 0.3s;
          padding: 0 10px;
          z-index: 1;
          i.fa-spinner {
            animation: spin 2s linear infinite;
          }
        }

        &.default {
          .icon {
            font-size: 0.8em;
          }
        }
      }

      & > * {
        &:first-child {
          :after {
            position: absolute;
            top: 16px;
            left: 50%;
            display: block;
            width: 9999px;
            height: 2px;
            content: '';
            background-color: #ccc;
          }
        }
      }

      & > * {
        &:last-child {
          .icon {
            padding-right: 1000px;
            margin-right: -1000px;
          }
        }
      }
    }
  }
`;
