import cn from 'classnames';
import { Form, Localization, Modal } from 'connex-cds';
import { find, isEmpty, omit } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cacheItem } from '../../../../../util/cache';
import { useCompanySetup, useProductSetup, useVehicleSetup, useVehicleTypeSetup } from '../MasterDataProvider';
import { useTicketContext } from '../TicketContext';
import { useCertificationLoss } from '../useCertificationLoss';
import { UnFinalize } from './modal/UnFinalize';
import steps from './steps';
import style from './style';
import { ResponsiveWizardSteps } from './wizard-steps/ResponsiveWizardSteps';
import { STEP_STATUS } from './wizard-steps/WizardSteps';
import pkg from '../../../../../../package.json';
import { useProbeListener } from '../useProbeListener';
import { useGetOrderRecipients } from '../../../../../query-hooks/order-recipients';
import { useCreateOrderPrice } from '../../../../../query-hooks/order';
import { cleanupLineItems } from '../../../../../util/cleanupLineItems';

const Styled = styled.div`
  ${style}
`;

export const Wizard = () => {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const { ticket, finalized, submitted, setFinalized, paymentMethod } = useTicketContext();
  const { errors, values, setFieldValue } = Form.useFormContext();
  const [currentStep, setCurrentStep] = React.useState('ticketDetail');
  const translateMessage = Localization.useTranslateMessage();
  const [stepTouched, setStepTouched] = React.useState({ ticketDetail: true });

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const productSetup = useProductSetup();
  const createPricing = useCreateOrderPrice(ticket?.orderRef);

  const orderRecipients = useGetOrderRecipients(ticket?.orderRef, ticket?.crn, companySetup);

  const setPricing = React.useCallback(
    async items => {
      return await createPricing({ lineItems: items }).then(response => {
        setFieldValue(`pricing`, response || []);
        return response;
      });
    },
    [createPricing, setFieldValue]
  );

  useProbeListener();
  useCertificationLoss();

  React.useEffect(() => {
    cacheItem(`state`, JSON.stringify({ [ticket.crn]: values }));
  }, [ticket.crn, values]);

  React.useEffect(() => {
    if (orderRecipients?.data?.recipients) {
      setFieldValue(`orderRecipients`, orderRecipients?.data?.recipients || []);
      if (orderRecipients?.data?.recipients?.length) setFieldValue('hasSentRecipients', true);
    }
  }, [orderRecipients?.data?.recipients, setFieldValue]);

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [vehicleSetup.data, ticket?.vehicleId]);

  const isEnablePaymentAndCostWorkflows = React.useMemo(
    () => companySetup?.data?.isEnablePaymentAndCostWorkflows ?? false,
    [companySetup?.data?.isEnablePaymentAndCostWorkflows]
  );

  React.useEffect(() => {
    if (!paymentMethod || !isEnablePaymentAndCostWorkflows) {
      return;
    }

    if (paymentMethod?.isCOD) {
      const products = productSetup?.data
        ?.filter?.(product => product.status === 'ACTIVE')
        ?.filter?.(product =>
          companySetup?.data?.isMulticountry === true
            ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
            : product
        )
        ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length);

      const items = products.map(product => {
        const newLineItem = {
          item: {
            productRef: product.crn,
          },
        };
        return newLineItem;
      });

      setPricing(items);
    }

    const step = translatedSteps.find(step => step.id === 'paymentMethod');
    if (!paymentMethod.isCOD) {
      step.hidden = true;
    } else if (paymentMethod.isCOD) {
      setFieldValue('isExpectedPaymentMethod', true);
      step.hidden = false;
    }
  }, [
    companySetup?.data?.isMulticountry,
    isEnablePaymentAndCostWorkflows,
    paymentMethod,
    productSetup?.data,
    setFieldValue,
    setPricing,
    ticket?.origin?.address?.countryCode,
    vehicleType,
  ]);

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const translatedStepsStatus = React.useMemo(() => {
    let filteredSteps = steps;

    const errorsCleaned = cleanupLineItems(errors, values);

    if (companySetup?.data?.isWaterInWorkflow === false) {
      filteredSteps = steps.filter(step => step.id !== 'water');
    } else if (vehicleTypeData) {
      if (companySetup?.data?.isWaterInWorkflow !== false && vehicleTypeData?.isWaterInWorkflow !== true) {
        filteredSteps = steps.filter(step => step.id !== 'water');
      }
    }
  
    if (companySetup?.data?.isReturnedMaterialInWorkflow === false || ticket?.customData?.ticketType === 'PMP') {
      filteredSteps = filteredSteps.filter(step => step.id !== 'returnedMaterial');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isReturnedMaterialInWorkflow !== false &&
        vehicleTypeData?.isReturnedMaterialInWorkflow !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'returnedMaterial');
      }
    }
    if (companySetup?.data?.isMaterialServiceInWorkflow === false) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'materialService');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialServiceInWorkflow !== false &&
        vehicleTypeData?.isMaterialServiceInWorkflow !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'materialService');
      }
    }
    if (ticket?.customData?.ticketType !== 'PMP') {
      filteredSteps = filteredSteps.filter(step => step.id !== 'totalPumpedQuantity');
    }
    if (companySetup.data?.isDotEnabled !== true) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'dotWorkflow');
    }
    if (companySetup?.data?.isMaterialComplianceEnabled === false) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'materialCompliance');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialComplianceEnabled !== false &&
        vehicleTypeData?.isMaterialComplianceEnabled !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'materialCompliance');
      }
    }
    if (companySetup?.data?.isRiskAssessmentEnabled !== true) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'riskAssessment');
    }
    return filteredSteps.map(step => {
      const paths = [].concat(step.path);

      let status = STEP_STATUS.DEFAULT;
      if (step.id === 'water') {
        if (
          values?.waterAddedEvents.some(item => item.suggestedToDriver) &&
          !stepTouched.water &&
          !!errorsCleaned.waterAddedEvents
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      } else if (step.id === 'materialService') {
        if (
          values?.lineItems.some(item => item.requiresDriverReview || item.suggestedToDriver) &&
          !stepTouched.materialService &&
          vehicleTypeData?.isMaterialServiceInWorkflow !== false &&
          !!errorsCleaned.lineItems
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      } else if (step.id === 'returnedMaterial') {
        if (
          companySetup?.data?.isReturnedMaterialMandatory === true &&
          !stepTouched.returnedMaterial &&
          vehicleTypeData?.isReturnedMaterialInWorkflow !== false &&
          ticket?.customData?.ticketType !== 'PMP'
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      } else if (step.id === 'totalPumpedQuantity') {
        if (ticket?.customData?.ticketType === 'PMP') {
          status = STEP_STATUS.MANDATORY;
        }
      }
      if (paths.some(p => !!errorsCleaned[p]) && stepTouched[step.id]) {
        status = STEP_STATUS.ERROR;
      } else if (stepTouched[step.id]) {
        status = STEP_STATUS.OK;
      }
      return {
        ...step,
        title: translateMessage(step.id, { value: ticket?.id }),

        status,
      };
    });
  }, [
    companySetup?.data?.isMaterialComplianceEnabled,
    companySetup?.data?.isMaterialServiceInWorkflow,
    companySetup?.data?.isReturnedMaterialInWorkflow,
    companySetup?.data?.isReturnedMaterialMandatory,
    companySetup?.data?.isWaterInWorkflow,
    errors,
    stepTouched,
    ticket?.customData?.ticketType,
    ticket?.id,
    translateMessage,
    values,
    vehicleTypeData,
  ]);

  const translatedSteps = React.useMemo(() => {
    const ticketEvents = Object.keys(ticket?.ticketEvents);
    return translatedStepsStatus.map(step => {
      let disabled = false;
      let loading = false;
      let hidden = false;
      if (step.id === 'water' || step.id === 'materialService') {
        if (
          !ticketEvents?.some(event => ['ARRIVE_JOB', 'READY_TO_PUMP', 'UNLOADING', 'START_PUMPING'].includes(event))
        ) {
          disabled = true;
        }
      } else if (step.id === 'totalPumpedQuantity') {
        if (!ticketEvents?.some(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))) {
          disabled = true;
        }
      } else if (step.id === 'returnedMaterial') {
        if (
          !ticketEvents?.some(event => ['ARRIVE_JOB', 'UNLOADING', 'END_UNLOADING', 'LEAVE_JOB'].includes(event)) ||
          (!ticketEvents?.some(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event)) &&
            !companySetup?.data?.isRejectLoadsEnable)
        ) {
          disabled = true;
        }
      } else if (step.id === 'finalize') {
        const isMaterialServiceNotInWorkflow = !companySetup?.data?.isMaterialServiceInWorkflow === false;
        const isVehicleTypeMaterialServiceNotTrue = vehicleTypeData?.isMaterialServiceInWorkflow !== true;
        const shouldOmitLineItemsByWorkflow = isMaterialServiceNotInWorkflow || isVehicleTypeMaterialServiceNotTrue;

        let errorsToCheck = omit(errors, 'ticketEvents');
        if (shouldOmitLineItemsByWorkflow) {
          errorsToCheck = omit(errors, 'ticketEvents', 'lineItems');
        }

        const itemsNotSuggestedByDriver = values?.lineItems?.reduce((acc, el, pos) => {
          if (!el?.suggestedToDriver) acc.push(`lineItems[${pos}]`);
          return acc;
        }, []);
        if (itemsNotSuggestedByDriver?.length) {
          errorsToCheck = omit(errors, 'ticketEvents', ...itemsNotSuggestedByDriver);

          const lineItemsErrors = errorsToCheck?.lineItems?.filter(Boolean);
          if (!lineItemsErrors?.length) {
            delete errorsToCheck.lineItems;
          }
          if (lineItemsErrors?.length) {
            errorsToCheck.lineItems = lineItemsErrors;
          }
        }

        const hasMandatoryOrErrorStatus = translatedStepsStatus?.some(
          s => s.status === 'mandatory' || s.status === 'error'
        );
        const originalCondition = !isEmpty(errorsToCheck) || hasMandatoryOrErrorStatus;
        const finishEvents = ticketEvents?.some(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event));

        if (companySetup?.data?.rejectLoadStep === 'returnedMaterial' || !companySetup?.data?.rejectLoadStep) {
          if (!finishEvents || originalCondition) {
            disabled = true;
          }
        } else if (!ticketEvents?.some(event => ['ARRIVE_JOB'].includes(event)) || originalCondition) {
          disabled = true;
        }
      } else if (step.id === 'paymentMethod') {
        if (!paymentMethod || !paymentMethod.isCOD || !isEnablePaymentAndCostWorkflows) {
          hidden = true;
        }
      }
      return {
        ...step,
        disabled,
        loading,
        hidden,
      };
    });
  }, [errors, ticket?.ticketEvents, values?.lineItems, translatedStepsStatus, isEnablePaymentAndCostWorkflows]);

  React.useEffect(() => {
    if (finalized && stepId !== 'finalize') {
      setCurrentStep(stepId);
      setFinalized(false);

      return;
    }

    if (finalized || (!finalized && submitted)) {
      setCurrentStep('finalize');

      return;
    }

    setCurrentStep(stepId);
  }, [finalized, stepId, submitted]);

  const handleStepClick = React.useCallback(
    value => {
      setStepTouched(s => ({ ...s, [value]: true }));
      navigate(`../${value}`);
    },
    [navigate]
  );

  const { openModal, closeModal } = Modal.useModalContext();

  const handleBackClick = React.useCallback(() => {
    if (!companySetup?.data?.unFinalizePassword) {
      setFinalized(false);
      return;
    }

    const onOkay = password => {
      if (companySetup?.data?.unFinalizePassword === password) {
        setFinalized(false);
      }
      closeModal();
    };

    openModal({
      title: 'Modal',
      component: <UnFinalize onOkay={onOkay} onCancel={closeModal} />,
    });
  }, [closeModal, companySetup?.data, openModal, setFinalized]);

  const currentComponent = React.useMemo(() => {
    const target = find(steps, { id: currentStep });
    return target?.component;
  }, [currentStep]);

  return (
    <Styled className={cn('wizard', { finalized })}>
      {!finalized && !submitted ? (
        <div className="header">
          <div className="steps">
            <ResponsiveWizardSteps
              steps={translatedSteps}
              currentStep={currentStep}
              onClick={handleStepClick}
              handleBackClick={handleBackClick}
            />
          </div>
        </div>
      ) : null}
      <div className={cn('step-content')}>
        <div className="step-component">{React.cloneElement(currentComponent, { handleBackClick })}</div>
      </div>
      <div className="env">
        {window?.location?.hostname} v{pkg.version}
      </div>
    </Styled>
  );
};
