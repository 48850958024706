import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyEN_US = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>Command Alkon Incorporated Privacy Notice</h4>
      </div>
      <div id="overview_content">
        <p>
          <em>Last modified: September 26, 2023</em>
        </p>
        <h2>Overview</h2>
        <p>
          Command Alkon Incorporated, including its related companies (“CAI” or “we”), is an international commercial
          organization that provides software, services, and web solutions to companies in the ready-mix, aggregate, and
          bulk hauler industry through on-premise, software-as-a-service (“SaaS”), and other web-based methods. CAI
          respects the privacy of the users of its products and services and is committed to protecting their privacy
          and maintaining their trust. This Privacy Notice describes our practices for collecting, using, storing,
          transferring, disclosing, and otherwise processing personal data (otherwise referred to as Personal
          Information) in connection with your use of CAI products and/or services, websites, apps, and social media
          pages, as well as your interactions with CAI staff in meetings and at CAI events, including offline sales and
          marketing activities. Please read this Privacy Notice carefully to understand our policies and practices
          regarding treatment of personal data. By accessing our website and/or using our products or services, you
          consent to our collection, use, and disclosure of personal data as described in this Privacy Notice. If you
          have a privacy question or concern, please contact us per the “Contact Us” information at the bottom of this
          Privacy Notice.
        </p>
        <h2></h2>
        <h2>Contents</h2>
        <ul>
          <li>
            <a href="#summary_of_privacy_notice">Summary of Privacy Notice</a>
          </li>
          <li>
            <a href="#controller_processor">Controller/Processor</a>
          </li>
          <li>
            <a href="#to_whom_does_this_notice_apply">To Whom Does This Notice Apply</a>
          </li>
          <li>
            <a href="#how_we_collect_personal_data">How We Collect and Use Personal Data</a>
          </li>
          <li>
            <a href="#automatically_collected_information">Automatically Collected Data</a>
          </li>
          <li>
            <a href="#how_we_use_the_information_we_collect">How We Use the Data We Collect</a>
          </li>
          <li>
            <a href="#how_we_disclose_the_information_we_collect">How We Disclose the Data We Collect</a>
          </li>
          <li>
            <a href="#third_party_services">Third-Party Services</a>
          </li>
          <li>
            <a href="#security">Security</a>
          </li>
          <li>
            <a href="#retention_of_data">Retention of Data</a>
          </li>
          <li>
            <a href="#your_rights_and_choices">Your Rights and Choices</a>
          </li>
          <li>
            <a href="#international_data_transfers_and_the_data_privacy_framework">
              International Data Transfers and the Data Privacy Framework
            </a>
          </li>
          <li>
            <a href="#lawful_basis_for_processing">Legal Basis for Processing</a>
          </li>
          <li>
            <a href="#children_policy">Children’s Privacy</a>
          </li>
          <li>
            <a href="#changes_to_the_privacy_notice">Changes to the Privacy Notice</a>
          </li>
          <li>
            <a href="#contact_us">Contact Us</a>
          </li>
        </ul>
      </div>
      <div id="summary_of_privacy_notice">
        <h1>Summary of Privacy Notice</h1>
        <p>
          <strong>Application</strong> &ndash; Our Privacy Notice applies to the processing of personal data provided
          by: 1) visitors and users of the various CAI sites, apps, products, and/or services; 2) attendees at CAI and
          industry events; 3) customers and prospective customers and their representatives; 4) subscribers to our
          notices and/or newsletters; 5) suppliers and business partners and their representatives; 6) visitors to our
          offices; and 7) anyone else who provides personal data to CAI for any other purpose. For more details, see
          below.
        </p>
        <p>
          <strong>Types of Data Processed</strong> &ndash; CAI collects the personal data you provide. For example, when
          you sign up for an account, we may collect information like names, e-mail addresses, physical address, phone
          numbers, etc. You may also provide personal data to, for example, learn more about our products or sign up for
          our notifications. We may process the following personal data which we may (depending upon the circumstances)
          collect during website visits, marketing interactions, app use, and use of our products and/or services in the
          course of agreements with you and/or your employer: your name, contact information (e-mail address, telephone
          number, etc.), job information (employer, title, supervisor, etc.), certain ID numbers (driver&rsquo;s
          license, employee, etc.), job performance information and certifications, payment information, IP address,
          geolocation, signature, camera image, username and password, union information, ethnicity, some job-related
          medical information, messaging, and behavioral data and information about you received through placement of
          cookies and tracking pixels during platform use. For more details, see below.
        </p>
        <p>
          <strong>Processing Purpose</strong> &ndash; We process your personal data for the following purposes: 1)
          performance of agreements with you and/or your employer; 2) monitoring, development, support, and
          administration of apps, websites, and platforms; 3) security and fraud prevention; 4) our marketing purposes
          (we do not sell your personal data); 5) our business administration; and 6) behavioral analysis associated
          with platform use. For more details, see below.
        </p>
        <p>
          <strong>Lawful Basis for Processing</strong> &ndash; To the extent applicable to you, some jurisdictions
          require any processing of personal data to be supported by a legal basis. We generally rely on the following
          legal justifications for our processing activities: 1) the performance of an agreement with you and/or your
          employer; 2) pursuing our legitimate interests as long as they do not override your interests, rights and
          freedoms; 3) your consent; and 4) compliance with a legal obligation. For more details, see below.
        </p>
        <p>
          <strong>Data Transfers</strong> &ndash; We may transfer your personal data to other CAI affiliates and/or
          third parties (i.e. business partners, resellers, etc.), acquiring or acquired entities, service providers,
          and, in accordance with applicable law, governmental authorities, courts, external advisors, and similar third
          parties. Some data transfers may cross national borders. We will use all reasonable measures to ensure that
          your personal data remains confidential when transferred. We do not sell your personal data to any third
          parties. Additionally, CAI will not share your data with any third party without full disclosure, except as
          otherwise set forth in this Privacy Notice. For more details, see below.
        </p>
        <p>
          <strong>Data Retention and Deletion</strong> &ndash; Your personal data will be deleted once it is no longer
          needed for the purposes of the original processing, legitimate interest, or as required by applicable law. For
          more details, see below.
        </p>
        <p>
          <strong>Your Choices and Rights</strong> &ndash; Depending on your jurisdiction, you may have a number of
          rights with regard to your personal data, which may include the right to access your personal data, the right
          to obtain a copy of your personal data, the right to have your personal data transferred to a third party, the
          right to correct your personal data, the right to restrict certain processing, and/or the right to have your
          personal data erased. To exercise your rights related to your personal data, please use the &ldquo;Contact
          Us&rdquo; information at the bottom of this Privacy Notice. For more details, see below.
        </p>
        <p>
          <strong>Changes to this Privacy Notice</strong> &ndash; We reserve the right to change the terms of this
          Privacy Notice at will and at any time as required by changing practices or privacy legislation. The current
          version of this Privacy Notice will always be available via link from our websites, platforms, or through the
          &ldquo;Contact Us&rdquo; information at the bottom of this Privacy Notice. You should review this Privacy
          Notice periodically so that you keep up to date on our most current policies and practices.
        </p>
      </div>
      <div id="controller_processor">
        <h1>Detailed Privacy Notice</h1>
        <p>
          <strong>Controller/Processor &mdash;</strong> CAI may be the Data Controller or the Data Processor for
          purposes of processing personal data, depending on the circumstances. Where CAI processes data on behalf of
          our customers and/or their affiliates, CAI most often acts as a Data Processor. Where CAI processes personal
          data for its own purposes and outside the instruction of its customers and/or their affiliates, CAI may be a
          Data Controller. If you have any questions about CAI&rsquo;s role with regard to your personal data, please
          use the &ldquo;Contact Us&rdquo; information at the bottom of this Privacy Notice.
        </p>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <p>
          <strong>To Whom Does This Notice Apply &mdash;</strong> Our Privacy Notice applies to all non-employment
          processing of personal data by CAI regardless of the source of collection. This Privacy Notice applies to the
          processing of personal data provided by: 1) visitors and users of the various CAI sites, apps, products,
          and/or services; 2) attendees at CAI and industry events; 3) customers and prospective customers and their
          representatives; 4) subscribers to our notices, e-mails, and/or newsletters; 5) suppliers and business
          partners and their representatives; 6) visitors to our offices; and 7) anyone else who provides personal data
          to CAI for any other purpose.
        </p>
      </div>
      <div id="how_we_collect_personal_data">
        <h2>How We Collect Personal Data &mdash;</h2>
        <h3>Personal Data You Provide:</h3>
        <p>
          CAI collects the personal data you or your representative provides. This could happen at a CAI event, during a
          support event, through marketing, during a face-to-face interaction, etc. Examples of the types of personal
          data CAI may collect include, but may not be limited to, your: name, address, telephone number, e-mail
          address, employer, title, date of hire, supervisor, seniority, driver&rsquo;s license number, employee number,
          social security number, other tax ID number, job performance information and certifications, payment
          information, IP address and/or device identifier, geolocation, signature, still and/or video camera image,
          username and password, union information, ethnicity, some job-related medical information, messaging, and
          behavioral data and information about you received through placement of cookies and tracking pixels during
          platform use. If you have questions or concerns about any of these personal data types, please use the
          &ldquo;Contact Us&rdquo; information at the bottom of this Privacy Notice.
        </p>
        <h3>Data Collected Through Our Products and Services:</h3>
        <p>CAI obtains personal data in connection with providing its products and services, including:</p>
        <p>(i) SaaS products or services hosted for CAI customers;</p>
        <p>
          (ii) Web-based products or services for collaborative commerce solutions covering the entire ordering and
          purchasing process in the business-to-business market sector;
        </p>
        <p>
          (iii) On-premise software products licensed to a customer for use on their own premises through provision of
          professional services;
        </p>
        <p>(iv) On-premise hardware products sold to a customer for use on their own premises; and</p>
        <p>(v) Customer support services related to SaaS, web-based and on-premise software and hardware products.</p>
        <p>
          For instance, when our customers use the products or services above, they may provide details about their
          employees, including their names, job titles, e-mail addresses, login credentials, telephone numbers,
          addresses, dates of birth, driver&rsquo;s license numbers, and other information. Some of our web-based
          solutions enable customers to, for example, submit personal data to create users of the solution, to store
          transaction documents that may include some personal data of signatories or business contacts, to use
          geo-location, and to store contact information associated with trading partners. We often process personal
          data on behalf of our customers subject to a written contract. We do not control the data processing or
          protection practices of our customers (who may be your employer, service provider, or business partner), so
          their terms may differ from those set out in this Privacy Notice. Please note that where CAI collects personal
          data through products or services controlled by our customers, our customers are the Data Controller for what
          data is collected and how it is used and disclosed. In those instances, CAI acts as a Data Processor only. Any
          questions related to how our customers process, use or share the personal data they collect through our
          products or services should be directed to the relevant customer.
        </p>
      </div>
      <div id="automatically_collected_information">
        <h3>Automatically Collected Information:</h3>
        <p>
          As you navigate through and interact with our websites and/or SaaS products and applications, we may use
          automatic data collection technologies to collect certain information about your equipment, actions and
          patterns (“User Activity Information”), including: (i) details of your use, including traffic data, location
          data through geo-location technology, logs and other communication data and the resources that you access and
          use; and (ii) information about your device, including your operating system, IP address, and other mobile
          sign-on data. Some of this information may be considered personal data in your jurisdiction.
        </p>
        <p>
          The User Activity Information that we collect helps us to improve our websites and products, and to deliver a
          better and more personalized service by enabling us to estimate usage patterns, display information you
          request in your relevant time zone, and recognize you when you return to our website or product.
        </p>
        <p>
          The technologies we use for this automatic data collection may include web beacons, pixel tags, scripts, or
          cookies. A cookie is a small file placed on the hard drive of your device. We use cookies to help analyze
          usage, customize our services, measure effectiveness, and promote trust and safety. Cookies are discussed
          further below.
        </p>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <h2>How We Use the Information We Collect &ndash;</h2>
        <p>
          CAI uses the information it collects for the purposes of operating effectively, providing its products and
          services to customers, facilitating business between parties in the industry, and administering and managing
          its relationships with customers. We also use the information we collect to process, evaluate and respond to
          your requests; respond to inquiries and applications; create, administer and communicate with you about your
          account (including any purchases and payments); operate, evaluate and improve CAI&rsquo;s business (including
          developing new products and services, managing communications, performing market research, analyzing CAI
          products/services/websites, and performing accounting, auditing, billing, reconciliation, and collection
          activities); ensure the safety of CAI network services and resources; and to comply with applicable legal
          requirements.
        </p>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <h2>How We Disclose the Information We Collect </h2>
        <p>We may disclose some personal data that we collect as follows:</p>
        <ul>
          <li>To our affiliates and/or business partners with whom we have contractual relationships;</li>
          <li>
            To third parties, such as contractors, service providers, consultants and other agents (“Service
            Providers”), that provide assistance to our business. CAI works with Service Providers in some instances for
            a variety of reasons including, for example, credit card payment processing, hours of service tracking, data
            hosting, and accounting. Service Providers with whom we share such personal data generally are bound by
            confidentiality and privacy obligations and a list of such Service Providers can be found on our website (
            <b>www.commandalkon.com</b>) under the Legal tab (Sub-Processor List);
          </li>
          <li>To fulfill the purposes for which you or your employer provided such personal data;</li>
          <li>
            To a buyer or other successor of CAI or any of our affiliates in the event of an acquisition, merger,
            divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of CAI’s or
            any of our affiliates’ equity or assets, whether as a going concern or as part of bankruptcy, liquidation or
            similar proceedings, in which personal data is among the assets transferred; and
          </li>
          <li>For any other purpose disclosed by us when you provide the personal data.</li>
        </ul>
        <p>
          Notwithstanding any other provisions of this Privacy Notice, we also reserve the right to access, preserve and
          disclose any information we collect as we reasonably believe is necessary: (i) to respond to legal
          requirements, including complying with any court order, law or legal process and responding to any government
          or regulatory request, including responding to law enforcement or other government officials in response to a
          verified request relating to a criminal investigation or alleged illegal activity; (ii) to enforce or apply
          our policies and agreements; (iii) to detect, prevent or otherwise address fraud, security, trust and safety
          or technical issues; (iv) to respond to user support requests; or (v) to protect the rights, property, health
          or safety of CAI, our users, any third parties, or the public in general.
        </p>
        <h2>Additional Information for California and other U.S. State Residents </h2>
        <p>
          The personal information that we collect includes information within the categories of data in the table
          below. These categories also represent the categories of personal information that we have collected over the
          past 12 months. Note that the categories listed below are defined by California state law. Inclusion of a
          category in the list below indicates only that, depending on the services and products we provide you and/or
          your employer and business partner, we may collect or dispose of some information within that category. It
          does not necessarily mean that we collect or disclose all information listed in a particular category, nor do
          we collect all categories of information for all individuals. In addition, while we include data collected in
          the Business-to-Business context which is considered personal data under certain privacy laws like the EU and
          UK GDPR and the California Privacy Rights Act, it may not be considered personal data in other jurisdictions,
          including within other US states. We have disclosed information in each of the below categories with our
          affiliates and service providers for our business purposes within the past 12 months.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Category</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Sources</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Purpose of Processing</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier,
                  Internet Protocol address, email address, account name, Social Security number, driver’s license
                  number, or other similar identifiers.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data, as well as information regarding your purchasing tendencies obtained from our
                  business partners, is also used for marketing purposes, including offering you products and services
                  that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Information that identifies, relates to, describes, or is capable of being associated with, a
                  particular individual, including, but not limited to, your name, signature, Social Security number,
                  physical characteristics or description, address, telephone number, driver&#8217;s license or state
                  identification card number, medical information.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data is also used for marketing purposes, including offering you products and
                  services that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Commercial information, including records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming histories or tendencies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, or records we have about you in
                  the course of providing services or products.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data, as well as information regarding your purchasing tendencies obtained from our
                  business partners, is also used for marketing purposes, including offering you products and services
                  that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Internet or other electronic network activity information, including, but not limited to, browsing
                  history, search history, and information regarding a consumer’s interaction with an Internet Web site,
                  application, or advertisement.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, or through the use of cookies and
                  similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data is also used for marketing purposes, including offering you products and
                  services that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Geolocation data.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data, as well as information regarding your purchasing tendencies obtained from our
                  business partners, is also used for marketing purposes, including offering you and providing you with
                  products and services that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
                <p>
                  For visitors, this information is collected via our CCTV cameras, and for callers, it is collected via
                  audio recordings.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including recording sales calls and other calls. For visitors, visual data may
                  be processed in order to maintain security and accountability, and to comply with local laws,
                  including those related to health and safety.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Professional or employment-related information.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, or records we have about you in
                  the course of providing services or products.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and for
                  compliance management.
                </p>
                <p>
                  In addition, this data is also used for marketing purposes, including offering you products and
                  services that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Inferences drawn from any of the above categories of information to create a profile about a consumer
                  reflecting the consumer’s preferences, characteristics, psychological trends, predispositions,
                  behavior, attitudes, intelligence, abilities, and aptitudes.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
                <p>
                  In addition, this data is also used for marketing purposes, including offering you products and
                  services that may interest you through both direct and partner advertising.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Sensitive personal information, including: any personal information that reveals an individual’s
                  social security or other state identification number; account log-in, geolocation; ethnic origin,
                  union membership; contents of mail, email, or text messages, unless the business is the intended
                  recipient of the communication; and genetic data.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This information is collected directly from you and/or your employer, our business partners, our
                  service providers, your interaction with our services and products, records we have about you in the
                  course of providing services or products, or through the use of cookies and similar technologies.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  This data is processed in connection with a number of our operational functions to provide you with
                  products and services, including facilitating business between parties in the industry and
                  administering relationships with customers.
                </p>
                <p>
                  It is also processed in order to help manage and administer your account, as well as to detect
                  security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                  compliance management, data analytics and technological development of our systems.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          CAI does not sell your information to others, including for purposes of the California Consumer Privacy Act of
          2018 (“CCPA”) and California Privacy Rights Act of 2020 (“CPRA”).
        </p>
      </div>
      <div id="third_party_services">
        <h2>Third-Party Services &ndash;</h2>
        <p>
          You may be given the opportunity to elect to access and use third-party services or applications
          (&ldquo;ThirdParty Services&rdquo;) through some of our websites and/or products. This Privacy Notice
          addresses only CAI&rsquo;s collection, use and disclosure of information collected by CAI. It does not apply
          to the practices of third parties that we do not own, control, employ or manage, including, but not limited
          to, any Third-Party Services. By accessing or using third-party services or applications through our websites
          and/or products, you are directing us to disclose your information to the Third-Party Service on your behalf
          as requested by that third-party, and you agree that the third party&rsquo;s use or disclosure of your
          information will be governed by the third party&rsquo;s privacy policy and may be different from how we use
          and disclose your information.
        </p>
      </div>
      <div id="security">
        <h2>Security &ndash;</h2>
        <p>
          CAI utilizes reasonable and appropriate administrative, technical, and physical security controls commensurate
          with the types of data it processes to protect personal data from loss, misuse and unauthorized access,
          disclosure, alteration and/or destruction. Although we work hard to protect your privacy, no security controls
          are 100% effective and we cannot guarantee the security of information or that your personal data or private
          communications always will remain private. Unauthorized entry or use, hardware or software failure, and other
          factors may compromise the security of user information.
        </p>
        <p>
          The safety and security of your information also depends on you. Where we have given you (or where you have
          chosen) user log-in credentials (i.e. a user ID and password) for access to certain parts of our services or
          apps, you are responsible for keeping those user account credentials confidential. We ask that you not share
          your credentials with anyone. You are solely responsible for the protection of user account credentials and
          for all use of your account credentials that is under your control.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Retention of Information &ndash;</h2>
        <p>
          We will generally retain personal data for as long as is needed to fulfill the purposes outlined in this
          Privacy Notice. This retention is generally for the life of the relationship plus a short period of time as
          set forth in our data retention schedule. However, we may retain certain information longer as required by
          applicable law (such as tax, accounting or other legal requirements) or for legitimate business purposes. Once
          we no longer need to use your personal data to comply with our obligations, we will remove it from our systems
          and records and/or take steps to properly anonymize it so that you can no longer be identified from it. Each
          of the uses set forth in this Privacy Notice constitutes a legitimate interest of CAI to process or use the
          personal data collected or is necessary to fulfill a contract. If you do not agree with this approach, you may
          object to CAI&rsquo;s processing or use of your personal data by contacting CAI in writing via the methods
          listed below in the &ldquo;Contact Us&rdquo; section at the bottom of this Privacy Notice.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Your Rights and Choices </h2>
        <h3>Account Information &amp; Requests:</h3>
        <p>
          In accordance with applicable law depending upon jurisdiction (such as, for example, the EEA from 25 May 2018
          onwards or the State of California from 1 January 2020 onwards), you may have the following rights listed
          below with regard to your personal data:
        </p>
        <p>
          1) the right to access (this means you can request that we provide you with a copy of your personal data we
          have collected about you, the categories of sources from which the information was collected, the purposes of
          collecting the data, the categories of third parties we have shared the data with, and the categories of
          personal data that have been shared with third parties for a business purpose);
        </p>
        <p>
          2) the right to correct (this means you may notify us through the “Contact Us” section at the bottom of this
          Privacy Notice to correct any mistakes in your personal data or update your preferences; we may also not be
          able to accommodate your request if we believe it would violate any law or legal requirement or cause the
          information to be incorrect; data solely retained for data backup purposes is generally excluded);
        </p>
        <p>
          3) the right to transfer (this means you may request that we provide a copy of your personal data to a third
          party of your choosing);
        </p>
        <p>
          4) the right to restrict (where our processing of your personal data is necessary for our legitimate
          interests, depending on the applicable data protection/privacy law which applies, you can object to this
          processing at any time, subject to some limitations and exceptions; you may also be able to restrict certain
          types of processing of your personal data under certain circumstances; in particular, you can request we
          restrict our use of your personal data if you contest its accuracy, if the processing of your personal data is
          determined to be unlawful, or if we no longer need your personal data for processing but we have retained it
          as permitted by law);
        </p>
        <p>
          5) the right to withdraw consent (this mean that to the extent that our processing of your personal data is
          based on your consent, you may withdraw your consent at any time; withdrawing your consent will not, however,
          affect the lawfulness of the processing based on your consent before its withdrawal, and will not affect the
          lawfulness of our continued processing that is based on any other lawful basis for processing your personal
          data);
        </p>
        <p>
          6) the right to delete (this means you may ask us to delete your personal data from our systems and we will do
          so unless we are required to retain such information in order to provide services to you or we require such
          personal data to comply with our legal or business obligations under applicable law); or
        </p>
        <p>7) the right to say “no” to the sale of your personal data (Note: CAI does not sell personal data).</p>
        <p>
          You may be able to exercise some or all of these rights by logging into your account within the products that
          you use. Otherwise, to request such information directly, please submit a written request using the details
          provided in the &#8220;Contact Us&#8221; section at the bottom of this Privacy Notice.
        </p>
        <p>
          Please note that under California law, we are only obligated to respond to personal information access
          requests from the same consumer up to two times in a 12-month period. Under both EU and California law, if an
          individual makes unfounded, repetitive, or excessive requests (as determined in our reasonable discretion) to
          access Personal Data, we may charge a fee subject to a maximum set by law.
        </p>
        <p>
          Remember that in certain regions, you also have the right to complain to a data protection authority (“DPA”)
          about our collection and use of your personal data. For more information, please contact your local DPA.
        </p>
        <p>
          In the event CAI processes personal data about you on behalf of a customer, please direct your privacy
          inquiries and requests for access, correction or deletion of personal data to such customer.
        </p>
        <p>
          Before providing information you request in accordance with certain of these rights, we must be able to verify
          your identity. In order to verify your identity, you may need to submit information about yourself, including,
          to the extent applicable, providing answers to security questions, your name, government identification
          number, date of birth, contact information, or other personal identifying information. We will match this
          information against information we have previously collected about you to verify your identity and your
          request. If we are unable to verify your identity as part of your request, we will not be able to satisfy your
          request. We are not obligated to collect additional information in order to enable you to verify your
          identity. For deletion requests, you will be required to submit a verifiable request for deletion.
        </p>
        <p>
          If you would like to appoint an authorized agent to make a request on your behalf, you must provide the agent
          with written, signed permission to submit privacy right requests on your behalf, or provide a letter from your
          attorney. The agent or attorney must provide this authorization at the time of request. We may require you to
          verify your identity with us directly before we provide any requested information to your approved agent.
        </p>
        <p>Information collected for purposes of verifying your request will only be used for verification.</p>
        <p>
          If you chose to exercise any of these rights, to the extent that they apply, U.S. state law prohibits us from
          discriminating against you on the basis of choosing to exercise your privacy rights. We may, however, charge a
          different rate or provide a different level of service to the extent permitted by law.
        </p>
        <h3>Do Not Track Signals:</h3>
        <p>
          Some web browsers permit you to broadcast a signal to websites and online services indicating a preference
          that they “do not track” your online activities. At this time, we do not honor such signals and we do not
          modify what information we collect or how we use that information based upon whether such a signal is
          broadcast or received by us.
        </p>
        <h3>Promotional Communications:</h3>
        <p>
          From time to time, we may send you marketing communications about our products, in accordance with your
          preferences. You may opt out of receiving promotional messages from us at any time by following the
          instructions in those messages (often it will be a notice or link at the bottom of the message). If you opt
          out, we may still send you non-promotional communications, such as those about your account or our ongoing
          business relations. Requests to opt out of promotional communications may also be sent to CAI in writing via
          the methods listed below in the “Contact Us” section at the bottom of this Privacy Notice.
        </p>
        <h3>Cookies:</h3>
        <p>
          You can manage our use of cookies through the cookie banner that appears when you access our sites. If you
          prefer, you can usually choose to set your browser to remove or reject browser cookies or to clear local
          storage. You may also make any cookie management requests by contacting us in writing via the methods listed
          below in the “Contact Us” section at the bottom of this Privacy Notice. Please note that if you choose to
          remove or reject some cookies, this could affect the functionality of our website and services. We have an
          independent Cookie Policy available on our website (<b>www.commandalkon.com</b>) under the Legal tab that
          provides more detail.
        </p>
        <h3>Location Information:</h3>
        <p>
          You can turn location-based services on and off by adjusting the settings of your mobile device. Please note
          that if you choose to turn off location-based services, this could affect the full functionality of our
          products and services.
        </p>
        <h3>California Privacy Rights:</h3>
        <p>
          California law permits users who are California residents to request and obtain from us once a year, free of
          charge, a list of the third parties to whom we have disclosed their personal data (if any) for their direct
          marketing purposes in the prior calendar year, as well as the type of personal data disclosed to those
          parties. Except as otherwise provided in this Privacy Notice or per agreement with the data subject, CAI does
          not sell personal data to third parties for their own marketing purposes.
        </p>
      </div>
      <div id="international_data_transfers_and_the_data_privacy_framework">
        <h2>International Data Transfers and the Data Privacy Framework </h2>
        <p>
          CAI is based in the United States and has offices globally. To facilitate our operations and to help deliver
          our products and services, we may transfer personal data to the U.S. and any other country where our
          affiliates, vendors, consultants and service providers operate. Such countries may have laws which are
          different, and potentially not as protective, as the laws of your country of residence. If you are a Data
          Controller using CAI as a Data Processor and if the content or data that you store on or through CAI software,
          apps or websites contains the personal data of individuals from the EEA, you agree that you have the legal
          authority to transfer the personal data to CAI, including the transfer to countries such as the U.S. where the
          privacy protections and rights of authorities to access personal data may not be equivalent to those in the
          EEA.
        </p>
        <p>
          When we transfer personal data abroad, we will take appropriate safeguards to protect the information in
          accordance with this Privacy Notice and seek to ensure that we, along with any overseas recipients, comply
          with applicable privacy laws. CAI relies on EU Standard Contractual Clauses (“SCCs”) for legal transfer of
          personal data between its entities from jurisdictions subject to the EU General Data Protection Regulation
          (“GDPR”) to the U.S. CAI relies on the UK’s Data Transfer Agreement for legal transfer of personal data
          between its entities from jurisdictions subject to the UK General Data Protection Regulation (“UK GDPR”) to
          the U.S.
        </p>
        <p>
          In supplement to the SCCs, if CAI becomes aware that any governmental authority (including law enforcement)
          wishes to obtain access to or a copy of some or all of the personal data processed by CAI, whether on a
          voluntary or a mandatory basis, for purposes related to national security intelligence, then unless legally
          prohibited or under a mandatory legal compulsion that requires otherwise, CAI will: 1) immediately notify the
          party to whom the personal data applies (i.e. customer or vendor); 2) inform the relevant government authority
          that it has not been authorized to disclose the personal data and, unless legally prohibited, will need to
          immediately notify the party to whom the personal data applies; 3) inform the governmental authority that it
          should direct all requests or demands directly to the party to whom the personal data applies; and 4) not
          provide access to the personal data until authorized in writing by the party to whom the personal data applies
          or until compelled legally to do so. If legally compelled to do so, CAI will use reasonable and lawful efforts
          to challenge such prohibition or compulsion. If CAI is compelled to produce the personal data, CAI will only
          disclose personal data to the extent legally required to do so in accordance with applicable lawful process.
        </p>
        <p>
          CAI employs Data Protection Addendums (“DPAs”) with sub-processors who process personal data on behalf of CAI
          where required by applicable privacy laws.When we transfer personal data from the European Economic Area
          (“EEA”), the United Kingdom, or Switzerland to the United States, CAI complies with the EU-U.S. Data Privacy
          Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF, and the Swiss &#8211; U.S. Data Privacy
          Framework (Swiss-U.S. DPF)as set forth by the U.S. Department of Commerce. CAI has certified to the U.S.
          Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF
          Principles) with regard to the processing of personal data received from the European Union in reliance on the
          EU-U.S. DPF and from the United Kingdon (and Gibraltar) in reliance on the UK Extension to the EU-U.S. DPF.
          CAI has certified to the U.S Department of Commerce that it adheres to the Swiss-U.S. Data Privacy Framework
          Principles (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from
          Switzerland in reliance on the Swiss-U.S. DPF. If there is any conflict between the terms in this Privacy
          Notice and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern. To
          learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit{' '}
          <b>https://www.dataprivacyframework.gov/</b>.
        </p>
        <p>
          In compliance with the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF, CAI commits
          to resolve DPF Principles-related complaints about our collection and use of your personal information. EU,
          UK, and Swiss individuals with inquiries or complaints regarding our handling of personal data received in
          reliance on the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and/or the Swiss-U.S. DPF should first
          contact CAI as indicated in the “Contact Us” section at the bottom of this Privacy Notice.
        </p>
        <p>
          An individual has the possibility, under certain conditions, to invoke binding arbitration for complaints
          regarding DPF compliance not resolved by any of the other DPF mechanisms. For additional information see{' '}
          <b>https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2</b>. In compliance
          with the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF, CAI commits to refer
          unresolved complaints concerning our handling of personal data received in reliance on the EU-U.S. DPF, the UK
          Extension to the EU-U.S. DPF, and/or the Swiss-U.S. DPF to JAMS, an alternative dispute resolution provider
          based in the United States. If you do not receive timely acknowledgment of your DPF Principles-related
          complaint from us, or if we have not addressed your DPF Principles-related complaint to your satisfaction,
          please visit <b>https://www.jamsadr.com/file-a-dpf-claim</b>
          for more information or to file a complaint. The services of JAMS are provided at no cost to you.
        </p>
        <p>
          The United States Federal Trade Commission has jurisdiction over CAI’s compliance with the EU-U.S. DPF, the
          U.K. extension to the EU-U.S. DPF, and the Swiss-U.S. DPF. If CAI shares personal data collected in the EEA,
          the United Kingdom, or Switzerland with a third-party service provider that processes the data solely on CAI’s
          behalf, then CAI will be liable for that third party&#8217;s processing of such data in violation of the
          Principles, unless CAI can prove that it is not responsible for the event giving rise to the damage.
        </p>
      </div>
      <div id="lawful_basis_for_processing">
        <h2>Lawful Basis for Processing Personal Data of Individuals (EEA/UK) &ndash;</h2>
        <p>
          If you are an individual from the EEA or UK, our lawful basis for collecting and using personal data will
          depend on the personal data concerned and the specific context in which we collect it. We will only use your
          Personal Data for the purposes for which we collect such Personal Data as outlined below, unless we need to
          use it at a later date for another purpose that is compatible with the original purpose. If we need to further
          process your Personal Data for a purpose that is not compatible with the original purpose for collection, we
          will notify you and provide an explanation of the legal basis which allows us to do so. However, we generally
          rely on the following lawful justifications for our processing activities: 1) the performance of an agreement
          with you and/or your employer; 2) pursuing our legitimate interests as long as they do not override your
          interests, rights and freedoms; 3) your consent; and 4) compliance with a legal obligation. In some cases, we
          may also rely on GDPR Article 49 derogations, have a legal obligation to collect personal data, or may
          otherwise need the personal data to protect your vital interests or those of another person. More detail
          regarding lawful basis is provided below.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Purpose(s) for Processing </strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Legal Basis for Processing</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>To process applications and other agreements for our products and services.</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing of your personal data is necessary to perform a contract or enter into a contract
                      with you and/or your employer
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>To manage and administer contracts including service agreements with you and your employer.</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing of your personal data is necessary for us to comply with legal and regulatory
                      obligations
                    </p>
                  </li>
                  <li>
                    <p>
                      The processing is necessary to support our legitimate interests in managing our business (or those
                      of a third party) provided such interests are not overridden by your interests, rights, and
                      freedoms
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  To improve our products and services, to carry out market research, to perform data analytics, for
                  general risk modelling purposes, and for statistical analyses
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing is necessary to support our legitimate interests in managing our business (or those
                      of a third party) provided such interests are not overridden by your interests, rights, and
                      freedoms
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Marketing</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      We will seek your consent to the processing of your personal data for marketing &ndash; which you
                      may withdraw at any time
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>For the prevention and detection of fraud, money laundering or other crimes</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing of your personal data is necessary for us to comply with legal and regulatory
                      obligations or as authorized by applicable law
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>To manage our relationship with you and between you and your employer</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing of your personal data is necessary to perform a contract or enter into a contract
                      with you and/or your employer
                    </p>
                  </li>
                  <li>
                    <p>
                      The processing is necessary to support our legitimate interests in managing our business (or those
                      of a third party) provided such interests are not overridden by your interests, rights, and
                      freedoms
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>To provide the benefit of our products and services</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      The processing of your personal data is necessary to perform a contract or enter into a contract
                      with you and/or your employer
                    </p>
                  </li>
                  <li>
                    <p>
                      The processing is necessary to support our legitimate interests in managing our business (or those
                      of a third party) provided such interests are not overridden by your interests, rights, and
                      freedoms
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="children_policy">
        <h2>Children&rsquo;s Privacy &ndash;</h2>
        <p>
          CAI&rsquo;s business is not directed toward children and CAI does not knowingly collect or solicit any
          information from children or anyone under the age of 13. CAI does not knowingly allow such persons to utilize
          our products or services. In the event that we learn that we have inadvertently collected personal data from a
          child or someone under the age of 13, we will delete that information as quickly as possible. If you believe
          that we might have any information from or about a child, please contact us via the methods listed below in
          the &#8220;Contact Us&#8221; section at the bottom of this Privacy Notice.
        </p>
      </div>
      <div id="changes_to_the_privacy_notice">
        <h2>Changes to the Privacy Notice &ndash;</h2>
        <p>
          We may amend this Privacy Notice at any time by posting the amended Privacy Notice on our websites. The date
          the Privacy Notice was last revised is identified at the top of this Privacy Notice. All amended terms
          automatically take effect after they are initially posted on our website, unless a change in any applicable
          law requires immediate amendment. You are responsible for periodically checking this Privacy Notice for any
          changes.
        </p>
      </div>
      <div>
        <h2>Complaints &ndash;</h2>
        <p>
          Without prejudice to any other administrative or judicial remedy you might have, you may have the right to
          lodge a complaint with local state regulators/data protection authorities if you believe that we have
          infringed applicable privacy or data protection requirements when processing personal data about you.
        </p>
        <h3>In the UK, you may contact:</h3>
        <p>The Information Commissioner&rsquo;s Office</p>
        <p>
          Water Lane, Wycliffe House
          <br />
          Wilmslow &#8211; Cheshire SK9 5AF
        </p>
        <p>Tel. +44 1625 545 700</p>
        <p>
          Email: <b>casework@ico.org.uk</b>
        </p>
        <p>
          Website: <b>https://ico.org.uk</b>
        </p>
        <h3>For the EU, please see:</h3>
        <p>
          <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
        </p>
        <h2>Changes to the Privacy Notice &ndash;</h2>
        <p>
          We may amend this Privacy Notice at any time by posting the amended Privacy Notice on our websites. The date
          the Privacy Notice was last revised is identified at the top of this Privacy Notice. All amended terms
          automatically take effect after they are initially posted on our website, unless a change in any applicable
          law requires immediate amendment. You are responsible for periodically checking this Privacy Notice for any
          changes.
        </p>
      </div>
      <div id="contact_us">
        <h2>Contact Us </h2>
        <p>
          To contact CAI with questions or concerns about this Privacy Notice or CAI’s practices concerning personal
          data, please use the contact information below. If you are a resident in the EEA, please note that where CAI
          acts as a data controller responsible for your personal data, the legal entity is Command Alkon Incorporated
          in the United States. CAI will address all questions and complaints related to this Privacy Notice within a
          reasonable period.
        </p>
        <p>
          <strong>E-mail:</strong>
        </p>
        <p>
          <b>privacy@commandalkon.com</b>
        </p>
        <p>
          <strong>Or write to:</strong>
        </p>
        <p>Command Alkon Incorporated</p>
        <p>Chief Privacy Officer c/o The Legal Department</p>
        <p>
          6750 Crosby Court
          <br />
          Dublin, Ohio 43016
        </p>
        <p>
          <strong>Or call:</strong>
        </p>
        <p>1-800-624-1872 (U.S. toll free)</p>
        <p>0-800-022-9682 (International toll free)</p>
        <p>
          <strong>Website:</strong>
        </p>
        <p>
          <b>www.commandalkon.com</b>{' '}
        </p>
      </div>
    </Styled>
  );
};
