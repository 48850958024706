import { Form, Localization } from 'connex-cds';
import React from 'react';
import { Styled } from './style';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();

  if (!values?.returnReason && !values?.driverDidNotAdd) {
    return null;
  }

  return (
    <Styled className="returned-material-mobile">
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            <Localization.Translate stringId="returnedMaterial" data-testid="quantity-label" />
          </td>
        </tr>
        <tr>
          <td className="header-cell" data-testid={`${testId}-activity-value`}>
            <Localization.Translate stringId="quantity" data-testid="quantity-label" />
          </td>
          <td data-testid={`${testId}-concrete-value`}>
            <Localization.Uom
              uom={
                values?.returnConcreteOnTruck?.value
                  ? values?.returnConcreteOnTruck
                  : { value: '0', uomCode: values?.returnConcreteOnTruck?.uomCode }
              }
            />
          </td>
        </tr>

        <tr>
          <td className="header-cell">
            <Localization.Translate stringId="reason" data-testid="reason-label" />
          </td>
          <td data-testid={`${testId}-reason-value`}>
            {(values?.returnConcreteOnTruck?.value && values?.returnReason?.description) || ''}
          </td>
        </tr>
      </table>
    </Styled>
  );
};
