import styled from '@emotion/styled';
import { css } from 'styled-components';

export const StyledTable = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'head-fixed'
    'body-scrollable'
    'foot-fixed';
  grid-template-rows: min-content auto min-content;
  .added-items-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    & > * {
      width: 100%;
      padding: 0 0.2rem;
      box-sizing: border-box;
    }
  }
`;

export const StyledHeader = styled.div`
  grid-area: head-fixed;
  .added-items-row {
    height: 2rem;
    font-weight: bold;
    border-bottom: 1px solid #abaaab;
    background-color: #f8fafd;
    justify-content: left;
  }
`;

export const StyledBody = styled.div`
grid-area: body-scrollable;
overflow-y: auto;
scrollbar-color: var(--color-background-container) #ffffff;
scrollbar-width: thin;
background-color: #fff;
padding: 1rem;

display: grid;
grid-template-columns: 1fr 1fr;
gap: 1rem;

& > * {
  width: 100%;
}
`;

export const StyledBodyMobile = styled.div`
grid-area: body-scrollable;
overflow-y: auto;
scrollbar-color: var(--color-background-container) #ffffff;
scrollbar-width: thin;
background-color: #fff;
padding: 1rem;

display: grid;
grid-template-columns: 1fr; /* Cambiado a una sola columna */
gap: 1rem;

& > * {
  width: 100%;
}
`;

export const StyledFooter = styled.div`
  grid-area: foot-fixed;

  .added-items-row{
    display: flex;          
    flex-direction: column; 
    align-items: center; 
    background-color: #f8fafd;
    border-top: 2px solid #abaaab;
    padding: 1rem;
    height: auto;
    gap: 1rem;
    & > * {
      width: 100%;
      padding: 0.2rem 0;
      box-sizing: border-box;
    }
  }
`;
export default css`
  transition: all 0.3s;
  border-radius: var(--radius-container);
  color: var(--color-text-container);
  border: 1px solid var(--color-border-container);
  box-shadow: var(--boxShadow-container);
  background-color: #f8fafd;
  overflow: hidden;
`;

export const StyledDiv = styled.div`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    background-color: white;
    padding: 0 0.5rem;
    font-size: 0.8rem;
    position: absolute;
    top: -1rem;
    left: 0;
  }
`;
