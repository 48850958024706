import { Form, Drawer, Responsive, Localization } from 'connex-cds';
import React from 'react';
import { omit } from 'lodash';
import { useTicketContext } from '../../../../TicketContext';
import { RejectTicket } from './reject/RejectTicket';
import { FinalizeActionsMobile } from './FinalizeActionsMobile';
import { FinalizeActionsDesktop } from './FinalizeActionsDesktop';
import { useCompanySetup, useFormsAndWaiversSetup, useVehicleSetup } from '../../../../MasterDataProvider';
import { TermsAndConditionsFlow } from './accept/terms-and-conditions/TermsAndConditionsFlow';
import { AcceptanceSignature } from './accept/AcceptanceSignature';
import { activeFormsAndWaiversArray } from '../../../../getActiveForms';
import { cleanupLineItems } from '../../../../../../../../util/cleanupLineItems';

export const FinalizeActions = ({ ticketId, flow }) => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { userLocale } = Localization.useLocalizationContext();
  const { setFieldValue, errors, values } = Form.useFormContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { finalized, setFinalized, submitted } = useTicketContext();
  const { ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();

  const handleAcceptCancel = React.useCallback(() => {
    setFieldValue('signature', undefined);
    setFieldValue('termsAndConditionsAccepted', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleRejectCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'TERMS',
        formsAndWaiversSetup,
        vehicleSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, companySetup, translateObjects, userLocale]
  );

  const handleAcceptClick = React.useCallback(() => {
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId:
        !companySetup.data?.isTermsAndConditionsEnabled || activeFormsAndWaivers?.length === 0
          ? 'customerSignature'
          : 'termsAndConditions',
      component:
        !companySetup.data?.isTermsAndConditionsEnabled || activeFormsAndWaivers?.length === 0 ? (
          <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />
        ) : (
          <TermsAndConditionsFlow
            ticketId={ticketId}
            handleAcceptCancel={handleAcceptCancel}
            activeFormsAndWaivers={activeFormsAndWaivers}
          />
        ),
      width: '100%',
    });
  }, [handleAcceptCancel, openDrawer, ticketId, companySetup]);

  const handleRejectClick = React.useCallback(() => {
    openDrawer({
      onClose: handleRejectCancel,
      titleStringId: 'customerRejection',
      component: <RejectTicket onCancel={handleRejectCancel} />,
      width: '700px',
    });
  }, [handleRejectCancel, openDrawer]);

  const handleClick = React.useCallback(() => {
    setFinalized(true);
    setFieldValue('finalized', true);
  }, [setFieldValue, setFinalized]);

  const errorsToCheck = React.useMemo(() => cleanupLineItems(errors, values, 'ticketEvents'), [errors, values]);

  if (submitted) return null;

  return (
    <Responsive>
      <FinalizeActionsMobile
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
        errors={errorsToCheck}
      />
      <FinalizeActionsDesktop
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
        errors={errorsToCheck}
      />
    </Responsive>
  );
};
