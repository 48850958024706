import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization, Core, Form } from 'connex-cds';
import _ from 'lodash';

import style, { StyledBody, StyledFooter, StyledHeader, StyledTable, StyledDiv, StyledTableBatch, StyledBodyBatch, Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from './Style';

const { Translate } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'addedItemsGrid';
export const MaterialSummaryDesktop = ( {source, results} ) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledTable className={cn('products-table')}>
        <StyledHeader>
          <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="materialSummary" data-testid="products-label" />
          </div>
        </StyledHeader>
        <StyledBody>
          <div className={cn('products-row table-header')} data-testid={`${testId}-second-header-row`}>
            {
                source?.map(_data => {
                  return(
                    <Translate stringId={`${_data.labelStringId}`} data-testid="amount-label" />
                  )
                })
            }
          </div>
          <div className={cn('products-row')} key={2} data-testid={`${testId}-row-${1}`}>
            {
                 source?.map(_data => {
                  return(
                    <div data-testclass="cell-column-product" data-testid={`${testId}-row-${1}-column-product`} style={{ alignItems: 'center'}}>
                      {_.get(results, _data.paths[1]) ? translateMessage(_.get(results, _data.paths[1]),{ value: _.get(results, _data.paths[0])}) : _.get(results, _data.paths[0])}
                    </div>
                  )
                })
            }
          </div>
        </StyledBody>
        <StyledFooter>
          <div className={cn('products-row')}></div>
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
