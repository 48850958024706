import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 10px;
  height: 70vh;
  overflow: auto;
  width: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyDE = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>COMMAND ALKON INCORPORATED DATENSCHUTZHINWEIS</h4>
      </div>
      <div id="overview_content">
        <div>
          <p>
            <em>Letzte Änderung: 26 September 2023</em>
          </p>
          <h2>Überblick</h2>
          <p>
            Command Alkon Incorporated, einschließlich seiner verbundenen Unternehmen („CAI“ oder „wir“), ist eine
            internationale Handelsorganisation, die Software, Dienstleistungen und Weblösungen für Unternehmen in der
            Ready-Mix-, Aggregat- und Massenguttransportbranche vor Ort, Software-as-a-Service („SaaS“) und andere
            webbasierte Methoden anbietet. CAI respektiert die Privatsphäre der Nutzer seiner Produkte und
            Dienstleistungen und verpflichtet sich, ihre Privatsphäre zu schützen und ihr Vertrauen zu wahren. Diese
            Datenschutzerklärung beschreibt unsere Praktiken zur Erfassung, Nutzung, Speicherung, Übertragung,
            Offenlegung und sonstigen Verarbeitung personenbezogener Daten (auch als personenbezogene Daten bezeichnet)
            in Verbindung mit Ihrer Nutzung von CAI-Produkten und/oder -Dienstleistungen, Websites, Apps und
            Social-Media-Seiten sowie Ihren Interaktionen mit CAI-Mitarbeitern in Besprechungen und bei
            CAI-Veranstaltungen, einschließlich Offline-Vertriebs- und Marketingaktivitäten. Bitte lesen Sie sich diese
            Datenschutzerklärung sorgfältig durch, um unsere Richtlinien und Praktiken in Bezug auf den Umgang mit
            personenbezogenen Daten zu verstehen. Durch den Zugriff auf unsere Website und/oder die Nutzung unserer
            Produkte oder Dienstleistungen stimmen Sie der Erfassung, Verwendung und Weitergabe personenbezogener Daten
            zu, wie in dieser Datenschutzerklärung beschrieben. Wenn Sie eine Frage oder ein Anliegen zum Datenschutz
            haben, kontaktieren Sie uns bitte über die „Kontakt“ -Information am Ende dieser Datenschutzerklärung.{' '}
          </p>
          <h2>Inhalt</h2>
          <ul>
            <li>
              <a href="#summary_of_privacy_notice">Zusammenfassung der Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#controller_processor">Controller/Prozessor</a>
            </li>
            <li>
              <a href="#to_whom_does_this_notice_apply">Für wen gilt diese Mitteilung?</a>
            </li>
            <li>
              <a href="#how_we_collect_personal_data">Wie wir personenbezogene Daten erheben und verwenden</a>
            </li>
            <li>
              <a href="#automatically_collected_information">Automatisch gesammelte Daten</a>
            </li>
            <li>
              <a href="#how_we_use_the_information_we_collect">Wie wir die Daten verwenden, die wir sammeln</a>
            </li>
            <li>
              <a href="#how_we_disclose_the_information_we_collect">
                Wie wir die von uns gesammelten Daten weitergeben
              </a>
            </li>
            <li>
              <a href="#third_party_services">Dienste von Drittanbietern</a>
            </li>
            <li>
              <a href="#security">Sicherheit</a>
            </li>
            <li>
              <a href="#retention_of_data">Aufbewahrung von Daten</a>
            </li>
            <li>
              <a href="#your_rights_and_choices">Ihre Rechte und Wahlmöglichkeiten</a>
            </li>
            <li>
              <a href="#international_data_transfers_and_privacy_shield">
                Internationale Datenübertragungen und das Datenschutz-Framework
              </a>
            </li>
            <li>
              <a href="#lawful_basis_for_processing">Rechtliche Grundlage für die Verarbeitung</a>
            </li>
            <li>
              <a href="#children_policy">Datenschutz für Kinder </a>
            </li>
            <li>
              <a href="#changes_to_the_privacy_notice">Änderungen der Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#contact_us">Kontaktieren Sie uns</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="summary_of_privacy_notice">
        <div>
          <h4>Zusammenfassung der Datenschutzerklärung</h4>
          <p>
            <strong>Bewerbung</strong> &ndash; Unsere Datenschutzerklärung gilt für die Verarbeitung personenbezogener
            Daten, die bereitgestellt werden von: 1) Besuchern und Nutzern der verschiedenen Websites, Apps, Produkte
            und/oder Dienstleistungen von CAI; 2) Teilnehmern an CAI- und Branchenveranstaltungen; 3) Kunden und
            Interessenten und deren Vertretern; 4) Abonnenten unserer Mitteilungen und/oder Newsletter; 5) Lieferanten
            und Geschäftspartner und deren Vertreter; 6) Besucher unserer Büros; und 7) jeder andere, der CAI
            personenbezogene Daten für irgendwelche anderer Zweck. Weitere Informationen finden Sie unten.{' '}
          </p>
          <p>
            <strong>Arten der verarbeiteten Daten</strong> &ndash; CAI erhebt die personenbezogenen Daten, die Sie uns
            zur Verfügung stellen. Wenn Sie sich beispielsweise für ein Konto registrieren, können wir Informationen wie
            Namen, EMail-Adressen, Postanschrift, Telefonnummern usw. sammeln. Sie können auch persönliche Daten
            angeben, um beispielsweise mehr über unsere Produkte zu erfahren oder sich für unsere Benachrichtigungen
            anzumelden. Wir können die folgenden personenbezogenen Daten verarbeiten, die wir (je nach den Umständen)
            bei Webseitenbesuchen, Marketinginteraktionen, App-Nutzung und Nutzung unserer Produkte und/oder
            Dienstleistungen im Rahmen von Verträgen mit Ihnen und/oder Ihrem Arbeitgeber erheben können: Ihr Name,
            Kontaktinformationen (E-Mail-Adresse, Telefonnummer usw.), Berufsinformationen (Arbeitgeber, Titel,
            Vorgesetzter usw.), bestimmte ID-Nummern (Führerschein, Mitarbeiter usw.), Informationen zur beruflichen
            Leistung und Zertifizierungen, Zahlungsinformationen, IPAdresse, Geolokalisierung, Signatur, Kamerabild,
            Nutzername und Passwort, Gewerkschaftsinformationen, ethnische Zugehörigkeit, einige berufsbezogene
            medizinische Informationen, Nachrichten- und Verhaltensdaten und Informationen über Sie, die wir durch die
            Platzierung von Cookies und Tracking-Pixeln während der Nutzung der Plattform erhalten haben. Weitere
            Informationen finden Sie unten.{' '}
          </p>
          <p>
            <strong>Verarbeitungszweck</strong> &ndash; Wir verarbeiten Ihre personenbezogenen Daten für die folgenden
            Zwecke: 1) Erfüllung von Vereinbarungen mit Ihnen und/oder Ihrem Arbeitgeber; 2) Überwachung, Entwicklung,
            Support und Verwaltung von Apps, Websites und Plattformen; 3) Sicherheit und Betrugsprävention; 4) unsere
            Marketingzwecke (wir verkaufen Ihre personenbezogenen Daten nicht); 5) unsere Geschäftsverwaltung; und 6)
            Verhaltensanalysen im Zusammenhang mit der Plattformnutzung. Weitere Informationen finden Sie unten.{' '}
          </p>
          <p>
            <strong>Rechtliche Grundlage für die Verarbeitung</strong> &ndash; Soweit auf Sie zutrifft, verlangen einige
            Gerichtsbarkeiten, dass jede Verarbeitung personenbezogener Daten auf einer Rechtsgrundlage beruht. Wir
            stützen uns im Allgemeinen auf die folgenden rechtlichen Begründungen für unsere Verarbeitungsaktivitäten:
            1) die Erfüllung einer Vereinbarung mit Ihnen und/oder Ihrem Arbeitgeber; 2) Verfolgung unserer legitimen
            Interessen, solange sie Ihre Interessen, Rechte und Freiheiten nicht außer Kraft setzen; 3) Ihre Zustimmung;
            und 4) Einhaltung einer gesetzlichen Verpflichtung. Weitere Informationen finden Sie unten.{' '}
          </p>
          <p>
            <strong>Datenübertragungen</strong> &ndash; Wir können Ihre personenbezogenen Daten an andere mit CAI
            verbundene Unternehmen und/oder Dritte (d. h. Geschäftspartner, Wiederverkäufer usw.), erwerbende oder
            übernommene Unternehmen, Dienstleister und, gemäß geltendem Recht, an Regierungsbehörden, Gerichte, externe
            Berater und ähnliche Dritte weitergeben. Manche Datenübertragungen können nationale Grenzen überschreiten.
            Wir werden alle angemessenen Maßnahmen ergreifen, um sicherzustellen, dass Ihre personenbezogenen Daten
            vertraulich bleiben, wenn sie übertragen werden. Wir verkaufen Ihre personenbezogenen Daten nicht an Dritte.
            Darüber hinaus wird CAI Ihre Daten ohne vollständige Offenlegung nicht an Dritte weitergeben, sofern in
            dieser Datenschutzerklärung nichts anderes festgelegt ist. Weitere Informationen finden Sie unten.{' '}
          </p>
          <p>
            <strong>Aufbewahrung und Löschung von Daten</strong> &ndash; Ihre personenbezogenen Daten werden gelöscht,
            sobald sie für die Zwecke der ursprünglichen Verarbeitung, des berechtigten Interesses oder gemäß geltendem
            Recht nicht mehr benötigt werden. Weitere Informationen finden Sie unten.
          </p>
          <p>
            <strong>Ihre Wahlmöglichkeiten und Rechte</strong> &ndash; Abhängig von Ihrer Gerichtsbarkeit haben Sie
            möglicherweise eine Reihe von Rechten in Bezug auf Ihre personenbezogenen Daten, darunter das Recht, auf
            Ihre personenbezogenen Daten zuzugreifen, das Recht, eine Kopie Ihrer personenbezogenen Daten zu erhalten,
            das Recht, Ihre personenbezogenen Daten an Dritte übertragen zu lassen, das Recht, Ihre personenbezogenen
            Daten zu korrigieren, das Recht, bestimmte Verarbeitungen einzuschränken und/oder das Recht, Ihre
            personenbezogenen Daten löschen zu lassen. Um Ihre Rechte in Bezug auf Ihre personenbezogenen Daten
            auszuüben, verwenden Sie bitte die „Kontakt“ -Informationen am Ende dieser Datenschutzerklärung. Weitere
            Informationen finden Sie unten.
          </p>
          <p>
            <strong>Änderungen an dieser Datenschutzerklärung</strong> &ndash; Wir behalten uns das Recht vor, die
            Bedingungen dieser Datenschutzerklärung nach Belieben und jederzeit zu ändern, wenn dies aufgrund sich
            ändernder Praktiken oder Datenschutzgesetze erforderlich ist. Die aktuelle Version dieser
            Datenschutzerklärung wird immer über einen Link auf unseren Websites, Plattformen oder über die „Kontakt“
            -Informationen am Ende dieser Datenschutzerklärung verfügbar sein. Sie sollten diese Datenschutzerklärung
            regelmäßig überprüfen, damit Sie über unsere aktuellsten Richtlinien und Praktiken auf dem Laufenden sind.
          </p>
        </div>
      </div>
      <div id="controller_processor">
        <div>
          <h4>Ausführlicher Datenschutzhinweis</h4>
          <p>
            <strong>Controller/Prozessor &mdash;</strong> CAI kann je nach den Umständen der Datenverantwortliche oder
            der Datenverarbeiter für die Verarbeitung personenbezogener Daten sein. Wo CAI Daten im Auftrag unserer
            Kunden und/oder ihrer verbundenen Unternehmen verarbeitet, agiert CAI meistens als Datenverarbeiter. Wenn
            CAI personenbezogene Daten für eigene Zwecke und außerhalb der Anweisungen seiner Kunden und/oder ihrer
            verbundenen Unternehmen verarbeitet, kann CAI ein Datenverantwortlicher sein. Wenn Sie Fragen zur Rolle von
            CAI in Bezug auf Ihre personenbezogenen Daten haben, verwenden Sie bitte die „Kontakt“ -Information am Ende
            dieser Datenschutzerklärung.
          </p>
        </div>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <div>
          <p>
            <strong>Für wen gilt diese Mitteilung &mdash;</strong> Unsere Datenschutzerklärung gilt für die gesamte
            Verarbeitung personenbezogener Daten außerhalb des Beschäftigungsverhältnisses durch CAI, unabhängig von der
            Quelle der Erfassung. Diese Datenschutzerklärung gilt für die Verarbeitung personenbezogener Daten, die
            bereitgestellt werden von: 1) Besuchern und Nutzern der verschiedenen Websites, Apps, Produkte und/oder
            Dienstleistungen von CAI; 2) Teilnehmern an CAI- und Branchenveranstaltungen; 3) Kunden und Interessenten
            und deren Vertretern; 4) Abonnenten unserer Mitteilungen, E-Mails und/oder Newsletter; 5) Lieferanten und
            Geschäftspartner und deren Vertreter; 6) Besuchern unserer Büros; und 7) jeder anderen Person, die CAI
            personenbezogene Daten zur Verfügung stellt für irgendein anderer Zweck.
          </p>
        </div>
      </div>
      <div id="how_we_collect_personal_data">
        <div>
          <h2>Wie wir personenbezogene Daten sammeln &mdash;</h2>
          <h3>Personenbezogene Daten, die Sie uns zur Verfügung stellen:</h3>
          <p>
            CAI sammelt die personenbezogenen Daten, die Sie oder Ihr Vertreter zur Verfügung stellen. Das könnte bei
            einer CAI-Veranstaltung, während einer Support-Veranstaltung, durch Marketing, während einer persönlichen
            Interaktion usw. passieren. Beispiele für die Arten von personenbezogenen Daten, die CAI sammeln kann,
            umfassen, sind aber nicht beschränkt auf, Ihr: Name, Adresse, Telefonnummer, E-MailAdresse, Arbeitgeber,
            Titel, Einstellungsdatum, Vorgesetzter, Dienstalter, Führerscheinnummer, Mitarbeiternummer,
            Sozialversicherungsnummer, andere Steuer-Identifikationsnummer, Informationen zur Arbeitsleistung und
            Zertifizierungen, Zahlungsinformationen, IP-Adresse und/oder Gerätekennung, Geolokalisierung, Signatur,
            Foto- und/oder Videokamerabild, Nutzername und Passwort, Gewerkschaftsinformationen, ethnische
            Zugehörigkeit, einige berufsbezogene medizinische Informationen, Nachrichten und Verhaltensdaten und
            Informationen über Sie, die wir durch die Platzierung von Cookies und Tracking-Pixeln während der Nutzung
            der Plattform erhalten haben. Wenn Sie Fragen oder Bedenken zu einer dieser Arten von personenbezogenen
            Daten haben, verwenden Sie bitte die „Kontaktieren Sie uns“ -Informationen am Ende dieser
            Datenschutzerklärung.
          </p>
          <h3>Durch unsere Produkte und Dienstleistungen erhobene Daten:</h3>
          <p>
            CAI erhält personenbezogene Daten im Zusammenhang mit der Bereitstellung seiner Produkte und
            Dienstleistungen, darunter:{' '}
          </p>
          <p>(i) SaaS-Produkte oder -Dienste, die für CAI-Kunden gehostet werden;</p>
          <p>
            (ii) Webbasierte Produkte oder Dienstleistungen für kollaborative Handelslösungen, die den gesamten Bestell-
            und Kaufprozess im Business-to-Business-Marktsektor abdecken;
          </p>
          <p>
            (iii) Softwareprodukte vor Ort, die an einen Kunden zur Nutzung in seinen eigenen Räumlichkeiten lizenziert
            wurden, indem er professionelle Dienstleistungen anbietet;
          </p>
          <p>
            (iv) Hardwareprodukte vor Ort, die an einen Kunden verkauft werden, um ihn in seinen eigenen Räumlichkeiten
            zu verwenden; und{' '}
          </p>
          <p>
            (v) Kundendienstleistungen im Zusammenhang mit SaaS-, webbasierten und lokalen Software- und
            Hardwareprodukten.
          </p>
          <p>
            Wenn unsere Kunden beispielsweise die oben genannten Produkte oder Dienstleistungen nutzen, können sie
            Informationen über ihre Mitarbeiter angeben, einschließlich ihrer Namen, Berufsbezeichnungen,
            E-MailAdressen, Anmeldedaten, Telefonnummern, Adressen, Geburtsdaten, Führerscheinnummern und anderer
            Informationen. Einige unserer webbasierten Lösungen ermöglichen es Kunden beispielsweise, persönliche Daten
            zu übermitteln, um Benutzer der Lösung zu erstellen, Transaktionsdokumente zu speichern, die einige
            persönliche Daten von Unterzeichnern oder Geschäftskontakten enthalten können, Geolokalisierung zu verwenden
            und Kontaktinformationen zu Handelspartnern zu speichern. Wir verarbeiten häufig personenbezogene Daten im
            Namen unserer Kunden, die einem schriftlichen Vertrag unterliegen. Wir haben keine Kontrolle über die
            Datenverarbeitungs- oder Datenschutzpraktiken unserer Kunden (die Ihr Arbeitgeber, Dienstleister oder
            Geschäftspartner sein können), sodass ihre Bedingungen von denen in dieser Datenschutzerklärung abweichen
            können. Bitte beachten Sie, dass, wenn CAI personenbezogene Daten über Produkte oder Dienstleistungen
            sammelt, die von unseren Kunden kontrolliert werden, unsere Kunden die Datenverantwortlichen dafür sind,
            welche Daten gesammelt und wie sie verwendet und weitergegeben werden. In diesen Fällen fungiert CAI nur als
            Datenverarbeiter. Alle Fragen dazu, wie unsere Kunden die personenbezogenen Daten, die sie über unsere
            Produkte oder Dienstleistungen sammeln, verarbeiten, verwenden oder weitergeben, sollten an den jeweiligen
            Kunden gerichtet werden.
          </p>
        </div>
      </div>
      <div id="automatically_collected_information">
        <div>
          <h3>Automatisch gesammelte Informationen:</h3>
          <p>
            Während Sie durch unsere Websites und/oder SaaS-Produkte und -Anwendungen navigieren und mit ihnen
            interagieren, können wir automatische Datenerfassungstechnologien verwenden, um bestimmte Informationen über
            Ihre Ausrüstung, Aktionen und Muster zu sammeln („Benutzeraktivitätsinformationen“), darunter: (i) Details
            zu Ihrer Nutzung, einschließlich Verkehrsdaten, Standortdaten durch Geolokalisierungstechnologie, Protokolle
            und andere Kommunikationsdaten und die Ressourcen, auf die Sie zugreifen und die Sie verwenden; und (ii)
            Informationen über Ihr Gerät, einschließlich Ihres Betriebssystems, IP-Adresse, und andere mobile
            Anmeldedaten. Einige dieser Informationen können in Ihrem Zuständigkeitsbereich als personenbezogene Daten
            betrachtet werden.
          </p>
          <p>
            Die Informationen über Benutzeraktivitäten, die wir sammeln, helfen uns, unsere Websites und Produkte zu
            verbessern und einen besseren und persönlicheren Service zu bieten, indem sie es uns ermöglichen,
            Nutzungsmuster einzuschätzen, Informationen, die Sie in Ihrer jeweiligen Zeitzone anfordern, anzuzeigen und
            Sie wiederzuerkennen, wenn Sie zu unserer Website oder unserem Produkt zurückkehren.
          </p>
          <p>
            Die Technologien, die wir für diese automatische Datenerfassung verwenden, können Web-Beacons, PixelTags,
            Skripte oder Cookies beinhalten. Ein Cookie ist eine kleine Datei, die auf der Festplatte Ihres Geräts
            gespeichert wird. Wir verwenden Cookies, um die Nutzung zu analysieren, unsere Dienste zu personalisieren,
            die Effektivität zu messen und Vertrauen und Sicherheit zu fördern. Cookies werden weiter unten näher
            besprochen.
          </p>
        </div>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <div>
          <h2>Wie wir die gesammelten Informationen verwenden &ndash;</h2>
          <p>
            CAI verwendet die gesammelten Informationen, um effektiv zu arbeiten, Kunden ihre Produkte und
            Dienstleistungen zur Verfügung zu stellen, Geschäfte zwischen Branchenparteien zu erleichtern und ihre
            Kundenbeziehungen zu verwalten und zu verwalten. Wir verwenden die von uns gesammelten Informationen auch,
            um Ihre Anfragen zu bearbeiten, auszuwerten und zu beantworten; auf Anfragen und Bewerbungen zu antworten;
            Ihr Konto zu erstellen, zu verwalten und mit Ihnen darüber zu kommunizieren (einschließlich aller Käufe und
            Zahlungen); das Geschäft von CAI zu betreiben, zu bewerten und zu verbessern (einschließlich der Entwicklung
            neuer Produkte und Dienstleistungen, Verwaltung der Kommunikation, Durchführung von Marktforschung, Analyse
            von CAIProdukten/Dienstleistungen/Websites und Durchführung von Buchhaltungs-, Rechnungs-, Abstimmungs- und
            Inkassoaktivitäten); um die Sicherheit von CAI zu gewährleisten Ich vernetze Dienste und Ressourcen; und um
            die geltenden gesetzlichen Anforderungen zu erfüllen.
          </p>
        </div>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <div>
          <h2>Wie wir die Informationen, die wir sammeln, weitergeben &ndash;</h2>
          <p>Wir können einige personenbezogene Daten, die wir sammeln, wie folgt weitergeben:</p>
          <ul>
            <li>
              An unsere verbundenen Unternehmen und/oder Geschäftspartner, mit denen wir Vertragsbeziehungen haben;
            </li>
            <li>
              An unsere verbundenen Unternehmen und/oder Geschäftspartner, mit denen wir Vertragsbeziehungen haben;
            </li>
            <li>
              Um die Zwecke zu erfüllen, für die Sie oder Ihr Arbeitgeber solche personenbezogenen Daten bereitgestellt
              haben;
            </li>
            <li>
              An einen Käufer oder einen anderen Nachfolger von CAI oder einem unserer verbundenen Unternehmen im Falle
              einer Übernahme, Fusion, Veräußerung, Restrukturierung, Reorganisation, Auflösung oder sonstiger
              Veräußerung oder Übertragung einiger oder aller Anteile oder Vermögenswerte von CAI oder unseren
              verbundenen Unternehmen, sei es im Rahmen einer Unternehmensfortführung oder im Rahmen eines Konkurs-,
              Liquidations- oder ähnlichen Verfahrens, bei dem personenbezogene Daten zu den übertragenen
              Vermögenswerten gehören; und
            </li>
            <li>Für jeden anderen Zweck, den wir bei der Bereitstellung der personenbezogenen Daten angeben.</li>
          </ul>
          <p>
            Ungeachtet aller anderen Bestimmungen dieser Datenschutzerklärung behalten wir uns auch das Recht vor, auf
            alle Informationen, die wir sammeln, zuzugreifen, sie zu speichern und weiterzugeben, sofern wir
            vernünftigerweise davon ausgehen, dass sie notwendig sind: (i) um rechtlichen Anforderungen zu entsprechen,
            einschließlich der Einhaltung von Gerichtsbeschlüssen, Gesetzen oder rechtlichen Verfahren und der
            Beantwortung von Regierungs- oder behördlichen Anfragen, einschließlich der Beantwortung einer verifizierten
            Anfrage im Zusammenhang mit einer strafrechtlichen Untersuchung oder mutmaßlichen illegalen Aktivität; (ii)
            um durchzusetzen oder zu beantragen unsere Richtlinien und Vereinbarungen; ( iii) um Betrug, Sicherheit,
            Vertrauen und Sicherheit oder technische Probleme aufzudecken, zu verhindern oder anderweitig zu behandeln;
            (iv) um auf Support-Anfragen von Benutzern zu antworten; oder (v) um die Rechte, das Eigentum, die
            Gesundheit oder die Sicherheit von CAI, unseren Benutzern, Dritten oder der Öffentlichkeit im Allgemeinen zu
            schützen.
          </p>
          <h2>Zusätzliche Informationen für Einwohner Kaliforniens und anderer US-Bundesstaaten &ndash;</h2>
          <p>
            Die personenbezogenen Daten, die wir sammeln, umfassen Informationen innerhalb der Datenkategorien in der
            Tabelle unten. Diese Kategorien stellen auch die Kategorien personenbezogener Daten dar, die wir in den
            letzten 12 Monaten gesammelt haben. Beachten Sie, dass die unten aufgeführten Kategorien durch das Gesetz
            des Bundesstaates Kalifornien definiert sind. Die Aufnahme einer Kategorie in die folgende Liste bedeutet
            nur, dass wir, abhängig von den Dienstleistungen und Produkten, die wir Ihnen und/oder Ihrem Arbeitgeber und
            Geschäftspartner anbieten, einige Informationen innerhalb dieser Kategorie sammeln oder entsorgen können.
            Das bedeutet nicht unbedingt, dass wir alle Informationen sammeln oder weitergeben, die in einer bestimmten
            Kategorie aufgeführt sind, und wir sammeln auch nicht alle Kategorien von Informationen für alle Personen.
            Darüber hinaus beziehen wir zwar Daten ein, die im Business-to-Business-Kontext gesammelt wurden und die
            nach bestimmten Datenschutzgesetzen wie der EU- und britischen DSGVO und dem California Privacy Rights Act
            als personenbezogene Daten gelten, aber in anderen Jurisdiktionen, auch in anderen USBundesstaaten, gelten
            sie möglicherweise nicht als personenbezogene Daten. Wir haben in den letzten 12 Monaten Informationen in
            jeder der unten aufgeführten Kategorien an unsere verbundenen Unternehmen und Dienstleister für unsere
            Geschäftszwecke weitergegeben.{' '}
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Kategorie</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Quellen</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Zweck der Verarbeitung</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Identifikatoren wie ein echter Name, ein Alias, eine Postanschrift, eine eindeutige persönliche
                    Kennung, eine Online-Kennung, eine InternetProtokoll-Adresse, eine E-MailAdresse, ein Kontoname,
                    eine Sozialversicherungsnummer, eine Führerscheinnummer oder andere ähnliche Identifikatoren.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten sowie Informationen über Ihre Kauftendenzen, die wir von unseren
                    Geschäftspartnern erhalten haben, auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informationen, die eine bestimmte Person identifizieren, sich auf sie beziehen, sie beschreiben oder
                    mit ihr in Verbindung gebracht werden können, einschließlich, aber nicht beschränkt auf, Ihren
                    Namen, Ihre Unterschrift, Sozialversicherungsnummer, physische Merkmale oder Beschreibung, Adresse,
                    Telefonnummer, Führerschein- oder Personalausweisnummer, medizinische Informationen.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.{' '}
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Dienstleistungen und Produkten, Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von
                    Dienstleistungen oder Produkten haben, oder durch den Einsatz von Cookies und ähnlichen Technologien
                    gesammelt.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zuhelfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Kommerzielle Informationen, einschließlich Aufzeichnungen über persönliches Eigentum, gekaufte,
                    erhaltene oder in Betracht gezogene Produkte oder Dienstleistungen oder andere Kauf- oder
                    Konsumverläufe oder tendenzen.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten oder
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten sowie Informationen über Ihre Kauftendenzen, die wir von unseren
                    Geschäftspartnern erhalten haben, auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informationen zu Internet- oder anderen elektronischen Netzwerkaktivitäten, einschließlich, aber
                    nicht beschränkt auf, Browserverlauf, Suchverlauf und Informationen über die Interaktion eines
                    Verbrauchers mit einer Internet Website, Anwendung oder Werbung.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten oder durch den
                    Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, betrügerischen oder illegalen Aktivitäten zu
                    schützen, für das ComplianceManagement, die Datenanalyse und die technologische Entwicklung unserer
                    Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Geolokalisierungsdaten.</td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten sowie Informationen über Ihre Kauftendenzen, die wir von unseren
                    Geschäftspartnern erhalten haben, auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Audio-, elektronische, visuelle, thermische, olfaktorische oder ähnliche Informationen.</p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                  <p>
                    Für Besucher werden diese Informationen über unsere CCTVKameras gesammelt, und für Anrufer werden
                    sie über Audioaufnahmen gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden in Verbindung mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen zur Verfügung zu stellen, einschließlich der Aufzeichnung von
                    Verkaufsgesprächen und anderen Anrufen. Für Besucher können visuelle Daten verarbeitet werden, um
                    die Sicherheit und Rechenschaftspflicht zu gewährleisten und die lokalen Gesetze einzuhalten,
                    einschließlich derer, die sich auf Gesundheit und Sicherheit beziehen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Berufliche oder beschäftigungsbezogene Informationen.</td>
                <td class="vertical-align: baseline">
                  <p>
                    This information is collected directly from you and/or your employer, our business partners, our
                    service providers, your interaction with our services and products, or records we have about you in
                    the course of providing services or products.Diese Informationen werden direkt von Ihnen und/oder
                    Ihrem Arbeitgeber, unseren Geschäftspartnern, unseren Dienstleistern, Ihrer Interaktion mit unseren
                    Dienstleistungen und Produkten oder Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung
                    von Dienstleistungen oder Produkten haben, gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen und für das Compliance-Management.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Schlüsse, die aus einer der oben genannten Informationskategorien gezogen werden, um ein Profil über
                    einen Verbraucher zu erstellen, das die Vorlieben, Eigenschaften, psychologischen Trends,
                    Veranlagungen, Verhaltensweisen, Einstellungen, Intelligenz, Fähigkeiten und Fähigkeiten des
                    Verbrauchers widerspiegelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    It is also processed in order to help manage and administer your account, as well as to detect
                    security incidents, protect against malicious, deceptive, fraudulent or illegal activity, for
                    compliance management, data analytics and technological development of our systems.Es wird auch
                    verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen, Sicherheitsvorfälle
                    aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen Aktivitäten zu schützen,
                    für das Compliance-Management, die Datenanalyse und die technologische Entwicklung unserer Systeme.
                  </p>
                  <p>
                    Darüber hinaus werden diese Daten auch für Marketingzwecke verwendet, einschließlich der
                    Bereitstellung von Produkten und Dienstleistungen, die Sie interessieren könnten, sowohl durch
                    Direkt- als auch durch Partnerwerbung.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Sensible personenbezogene Daten, darunter: alle personenbezogenen Daten, die die
                    Sozialversicherungs- oder andere staatliche Identifikationsnummer einer Person preisgeben;
                    Kontoanmeldung, Geolokalisierung; ethnische Herkunft, Gewerkschaftszugehörigkeit; Inhalt von Post,
                    E-Mail oder Textnachrichten, sofern das Unternehmen nicht der beabsichtigte Empfänger der
                    Kommunikation ist; und genetische Daten.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Informationen werden direkt von Ihnen und/oder Ihrem Arbeitgeber, unseren Geschäftspartnern,
                    unseren Dienstleistern, Ihrer Interaktion mit unseren Dienstleistungen und Produkten,
                    Aufzeichnungen, die wir über Sie im Rahmen der Bereitstellung von Dienstleistungen oder Produkten
                    haben, oder durch den Einsatz von Cookies und ähnlichen Technologien gesammelt.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Diese Daten werden im Zusammenhang mit einer Reihe unserer betrieblichen Funktionen verarbeitet, um
                    Ihnen Produkte und Dienstleistungen anzubieten, einschließlich der Erleichterung von Geschäften
                    zwischen den Parteien in der Branche und der Verwaltung von Kundenbeziehungen.
                  </p>
                  <p>
                    Es wird auch verarbeitet, um Ihnen bei der Verwaltung und Verwaltung Ihres Kontos zu helfen,
                    Sicherheitsvorfälle aufzudecken, vor böswilligen, irreführenden, betrügerischen oder illegalen
                    Aktivitäten zu schützen, für das Compliance-Management, die Datenanalyse und die technologische
                    Entwicklung unserer Systeme.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            CAI verkauft Ihre Daten nicht an Dritte, auch nicht für Zwecke des California Consumer Privacy Act von 2018
            (&ldquo;CCPA&rdquo;) und des California Privacy Rights Act von (&ldquo;CPRA&rdquo;).
          </p>
        </div>
      </div>
      <div id="third_party_services">
        <div>
          <h2>Dienste von Drittanbietern &ndash;</h2>
          <p>
            Möglicherweise haben Sie die Möglichkeit, über einige unserer Websites und/oder Produkte auf Dienste oder
            Anwendungen von Drittanbietern (&ldquo;Dienste von Drittanbietern&rdquo;) zuzugreifen und diese zu nutzen.
            Diese Datenschutzerklärung bezieht sich nur auf die Erfassung, Verwendung und Weitergabe der von CAI
            gesammelten Informationen durch CAI. Es gilt nicht für die Praktiken Dritter, die wir nicht besitzen,
            kontrollieren, einsetzen oder verwalten, einschließlich, aber nicht beschränkt auf Dienste Dritter. Indem
            Sie über unsere Websites und/oder Produkte auf Dienste oder Anwendungen von Drittanbietern zugreifen oder
            diese nutzen, weisen Sie uns an, Ihre Daten in Ihrem Namen an den Drittanbieter-Service weiterzugeben, wie
            von diesem Drittanbieter gewünscht, und Sie erklären sich damit einverstanden, dass die Verwendung oder
            Offenlegung Ihrer Daten durch den Dritten der Datenschutzrichtlinie des Drittanbieters unterliegt und sich
            von der Art und Weise unterscheiden kann, wie wir Ihre Daten verwenden und weitergeben.
          </p>
        </div>
      </div>
      <div id="security">
        <div>
          <h2>Sicherheit &ndash;</h2>
          <p>
            CAI verwendet angemessene und angemessene administrative, technische und physische Sicherheitskontrollen,
            die der Art der verarbeiteten Daten entsprechen, um personenbezogene Daten vor Verlust, Missbrauch und
            unbefugtem Zugriff, Offenlegung, Änderung und/oder Zerstörung zu schützen. Obwohl wir hart daran arbeiten,
            Ihre Privatsphäre zu schützen, sind keine Sicherheitskontrollen zu 100% wirksam und wir können nicht
            garantieren, dass Ihre Informationen sicher sind oder dass Ihre persönlichen Daten oder private
            Kommunikation immer privat bleiben. Unbefugter Zugriff oder Nutzung, Hardware- oder Softwarefehler und
            andere Faktoren können die Sicherheit von Benutzerinformationen gefährden.
          </p>
          <p>
            Die Sicherheit Ihrer Daten hängt auch von Ihnen ab. Wo wir Ihnen Benutzeranmeldedaten (d. h. eine
            Benutzer-ID und ein Passwort) für den Zugriff auf bestimmte Teile unserer Dienste oder Apps gegeben haben
            (oder wo Sie gewählt haben), sind Sie dafür verantwortlich, diese Benutzerkontodaten vertraulich zu
            behandeln. Wir bitten Sie, Ihre Zugangsdaten mit niemandem zu teilen. Sie sind allein verantwortlich für den
            Schutz der Benutzerkontodaten und für die gesamte Verwendung Ihrer Kontoanmeldeinformationen, die unter
            Ihrer Kontrolle steht.
          </p>
        </div>
      </div>
      <div id="retention_of_data">
        <div>
          <h2>Aufbewahrung von Informationen &ndash;</h2>
          <p>
            Wir speichern personenbezogene Daten generell so lange, wie es für die Erfüllung der in dieser
            Datenschutzerklärung genannten Zwecke erforderlich ist. Diese Aufbewahrung gilt in der Regel für die Dauer
            der Beziehung plus einen kurzen Zeitraum, wie in unserem Datenaufbewahrungsplan festgelegt. Wir können
            jedoch bestimmte Informationen länger aufbewahren, wenn dies nach geltendem Recht (z. B. steuerliche,
            buchhalterische oder andere rechtliche Anforderungen) oder für legitime Geschäftszwecke erforderlich ist.
            Sobald wir Ihre personenbezogenen Daten nicht mehr verwenden müssen, um unseren Verpflichtungen
            nachzukommen, werden wir sie aus unseren Systemen und Aufzeichnungen entfernen und/oder Maßnahmen ergreifen,
            um sie ordnungsgemäß zu anonymisieren, sodass Sie anhand dieser Daten nicht mehr identifiziert werden
            können. Jede der in dieser Datenschutzerklärung dargelegten Nutzungen stellt ein berechtigtes Interesse von
            CAI an der Verarbeitung oder Nutzung der erhobenen personenbezogenen Daten dar oder ist für die Erfüllung
            eines Vertrags erforderlich. Wenn Sie mit diesem Ansatz nicht einverstanden sind, können Sie der
            Verarbeitung oder Nutzung Ihrer personenbezogenen Daten durch CAI widersprechen, indem Sie CAI schriftlich
            über die unten im Abschnitt „Kontakt“ am Ende dieser Datenschutzerklärung aufgeführten Methoden
            kontaktieren.
          </p>
        </div>
      </div>
      <div id="your_rights_and_choices">
        <div>
          <h2>Ihre Rechte und Wahlmöglichkeiten &ndash;</h2>
          <h3>Kontoinformationen und Anfragen:</h3>
          <p>
            Gemäß geltendem Recht, abhängig von der Gerichtsbarkeit (wie zum Beispiel der EWR ab dem 25. Mai 2018 oder
            der Bundesstaat Kalifornien ab dem 1. Januar 2020), haben Sie möglicherweise die folgenden Rechte, die unten
            aufgeführt sind, in Bezug auf Ihre personenbezogenen Daten:
          </p>
          <p>
            1) das Recht auf Zugriff (das bedeutet, dass Sie verlangen können, dass wir Ihnen eine Kopie Ihrer
            personenbezogenen Daten, die wir über Sie gesammelt haben, die Kategorien von Quellen, aus denen die
            Informationen gesammelt wurden, die Zwecke der Datenerhebung, die Kategorien von Dritten, mit denen wir die
            Daten geteilt haben, und die Kategorien von personenbezogenen Daten, die zu Geschäftszwecken an Dritte
            weitergegeben wurden, zur Verfügung stellen);
          </p>
          <p>
            2) das Recht auf Berichtigung (das bedeutet, dass Sie uns über den Abschnitt „Kontakt“ am Ende dieser
            Datenschutzerklärung benachrichtigen können, um Fehler in Ihren persönlichen Daten zu korrigieren oder Ihre
            Einstellungen zu aktualisieren; wir können Ihrer Anfrage möglicherweise auch nicht nachkommen, wenn wir
            glauben, dass dies gegen ein Gesetz oder eine gesetzliche Anforderung verstoßen würde oder dazu führen
            würde, dass die Informationen falsch sind; Daten, die ausschließlich zu Datensicherungszwecken aufbewahrt
            werden, sind generell ausgeschlossen);
          </p>
          <p>
            3) das Recht auf Übertragung (das heißt, Sie können verlangen, dass wir eine Kopie Ihrer personenbezogenen
            Daten an einen Dritten Ihrer Wahl weitergeben);
          </p>
          <p>
            4) das Recht auf Einschränkung (sofern unsere Verarbeitung Ihrer personenbezogenen Daten für unsere
            legitimen Interessen notwendig ist), je nach geltendem Datenschutz/Datenschutzgesetz, können Sie dieser
            Verarbeitung jederzeit widersprechen, vorbehaltlich einiger Einschränkungen und Ausnahmen; Sie können unter
            bestimmten Umständen auch bestimmte Arten der Verarbeitung Ihrer personenbezogenen Daten einschränken;
            insbesondere können Sie verlangen, dass wir unsere Verwendung Ihrer personenbezogenen Daten einschränken,
            wenn Sie deren Richtigkeit bestreiten, wenn die Verarbeitung Ihrer personenbezogenen Daten als rechtswidrig
            eingestuft wird, oder wenn wir nicht Ich benötige Ihre personenbezogenen Daten länger für die Verarbeitung,
            aber wir haben sie aufbewahrt, soweit gesetzlich zulässig);
          </p>
          <p>
            5) das Recht, die Einwilligung zu widerrufen (das heißt, soweit unsere Verarbeitung Ihrer personenbezogenen
            Daten auf Ihrer Zustimmung basiert, können Sie Ihre Einwilligung jederzeit zurückziehen; der Widerruf Ihrer
            Einwilligung hat jedoch keinen Einfluss auf die Rechtmäßigkeit der Verarbeitung aufgrund Ihrer Einwilligung
            vor deren Widerruf und hat keinen Einfluss auf die Rechtmäßigkeit unserer fortgesetzten Verarbeitung, die
            auf einer anderen rechtmäßigen Grundlage für die Verarbeitung Ihrer personenbezogenen Daten basiert);{' '}
          </p>
          <p>
            6) das Recht auf Löschung (das heißt, Sie können uns bitten, Ihre personenbezogenen Daten aus unseren
            Systemen zu löschen, und wir werden das tun, es sei denn, wir sind verpflichtet, diese Informationen
            aufzubewahren, um Ihnen Dienstleistungen zu erbringen, oder wir benötigen diese personenbezogenen Daten, um
            unseren gesetzlichen oder geschäftlichen Verpflichtungen nach geltendem Recht nachzukommen); oder
          </p>
          <p>
            7) das Recht, „Nein“ zum Verkauf Ihrer personenbezogenen Daten zu sagen (Hinweis: CAI verkauft keine
            personenbezogenen Daten).
          </p>
          <p>
            Möglicherweise können Sie einige oder alle dieser Rechte ausüben, indem Sie sich innerhalb der Produkte, die
            Sie verwenden, in Ihr Konto einloggen. Andernfalls, um solche Informationen direkt anzufordern, reichen Sie
            bitte eine schriftliche Anfrage unter Verwendung der im Abschnitt „Kontakt“ am Ende dieser
            Datenschutzerklärung angegebenen Daten ein.
          </p>
          <p>
            Bitte beachten Sie, dass wir nach kalifornischem Recht nur verpflichtet sind, innerhalb von 12 Monaten bis
            zu zweimal auf Anfragen zu personenbezogenen Daten zu antworten, die von demselben Verbraucher gestellt
            wurden. Sowohl nach europäischem als auch nach kalifornischem Recht können wir eine Gebühr erheben, wenn
            eine Person unbegründete, wiederholte oder übermäßige Anfragen (wie nach unserem vernünftigen Ermessen
            festgelegt) stellt, bis zu einem gesetzlich festgelegten Höchstbetrag.
          </p>
          <p>
            Denken Sie daran, dass Sie in bestimmten Regionen auch das Recht haben, sich bei einer Datenschutzbehörde
            („DPA“) über unsere Erfassung und Verwendung Ihrer personenbezogenen Daten zu beschweren. Für weitere
            Informationen wenden Sie sich bitte an Ihre örtliche Datenschutzbehörde
          </p>
          <p>
            Für den Fall, dass CAI personenbezogene Daten über Sie im Auftrag eines Kunden verarbeitet, richten Sie Ihre
            Datenschutzanfragen und Anfragen zum Zugriff, zur Korrektur oder Löschung personenbezogener Daten bitte an
            diesen Kunden.
          </p>
          <p>
            Bevor wir Informationen bereitstellen, die Sie gemäß bestimmten dieser Rechte anfordern, müssen wir in der
            Lage sein, Ihre Identität zu überprüfen. Um Ihre Identität zu überprüfen, müssen Sie möglicherweise
            Informationen über sich selbst angeben, einschließlich, soweit zutreffend, der Bereitstellung von Antworten
            auf Sicherheitsfragen, Ihres Namens, Ihrer behördlichen Identifikationsnummer, Ihres Geburtsdatums, Ihrer
            Kontaktinformationen oder anderer personenbezogener Daten. Wir werden diese Informationen mit Informationen
            abgleichen, die wir zuvor über Sie gesammelt haben, um Ihre Identität und Ihre Anfrage zu überprüfen. Wenn
            wir Ihre Identität im Rahmen Ihrer Anfrage nicht überprüfen können, können wir Ihrer Anfrage nicht
            nachkommen. Wir sind nicht verpflichtet, zusätzliche Informationen zu sammeln, damit Sie Ihre Identität
            überprüfen können. Für Löschungsanträge müssen Sie einen überprüfbaren Löschantrag stellen.{' '}
          </p>
          <p>
            Wenn Sie einen bevollmächtigten Vertreter benennen möchten, der in Ihrem Namen eine Anfrage stellt, müssen
            Sie dem Bevollmächtigten eine schriftliche, unterschriebene Genehmigung zur Einreichung von
            Datenschutzanfragen in Ihrem Namen vorlegen oder ein Schreiben Ihres Anwalts vorlegen. Der Vertreter oder
            Anwalt muss diese Genehmigung zum Zeitpunkt der Anfrage vorlegen. Wir können verlangen, dass Sie Ihre
            Identität direkt bei uns verifizieren, bevor wir die angeforderten Informationen an Ihren autorisierten
            Vertreter weitergeben.
          </p>
          <p>
            Informationen, die zur Überprüfung Ihrer Anfrage gesammelt wurden, werden nur zur Überprüfung verwendet.
          </p>
          <p>
            Wenn Sie sich dafür entscheiden, eines dieser Rechte auszuüben, verbietet es uns das Gesetz der
            USBundesstaaten, Sie zu diskriminieren, sofern Sie sich dafür entscheiden, Ihre Datenschutzrechte auszuüben.
            Wir können jedoch einen anderen Tarif berechnen oder ein anderes Serviceniveau anbieten, soweit dies
            gesetzlich zulässig ist.
          </p>
          <h3>Signale nicht verfolgen:</h3>
          <p>
            Einige Webbrowser ermöglichen es Ihnen, ein Signal an Websites und Online-Dienste zu senden, das darauf
            hinweist, dass sie Ihre Online-Aktivitäten „nicht verfolgen“. Derzeit respektieren wir solche Signale nicht
            und wir ändern nicht, welche Informationen wir sammeln oder wie wir diese Informationen verwenden, je
            nachdem, ob ein solches Signal von uns gesendet oder empfangen wird.
          </p>
          <h3>Werbemitteilungen:</h3>
          <p>
            Von Zeit zu Zeit senden wir Ihnen möglicherweise Marketingmitteilungen über unsere Produkte, entsprechend
            Ihren Präferenzen. Sie können den Erhalt von Werbebotschaften von uns jederzeit abbestellen, indem Sie den
            Anweisungen in diesen Nachrichten folgen (oft ist es ein Hinweis oder ein Link am Ende der Nachricht). Wenn
            Sie sich abmelden, können wir Ihnen trotzdem Werbemitteilungen schicken, wie zum Beispiel solche über Ihr
            Konto oder unsere laufenden Geschäftsbeziehungen. Anfragen zur Abmeldung von Werbemitteilungen können auch
            schriftlich über die unten im Abschnitt „Kontakt“ am Ende dieser Datenschutzerklärung aufgeführten Methoden
            an CAI gesendet werden.
          </p>
          <h3>Kekse:</h3>
          <p>
            Sie können unsere Verwendung von Cookies über das Cookie-Banner verwalten, das erscheint, wenn Sie auf
            unsere Websites zugreifen. Wenn Sie es vorziehen, können Sie in der Regel Ihren Browser so einstellen, dass
            er Browser-Cookies entfernt oder ablehnt oder den lokalen Speicher löscht. Sie können auch Anfragen zur
            Verwaltung von Cookies stellen, indem Sie uns schriftlich über die unten im Abschnitt „Kontakt“ am Ende
            dieser Datenschutzerklärung aufgeführten Methoden kontaktieren. Bitte beachten Sie, dass, wenn Sie sich
            dafür entscheiden, einige Cookies zu entfernen oder abzulehnen, dies die Funktionalität unserer Website und
            Dienste beeinträchtigen könnte. Wir haben eine unabhängige CookieRichtlinie, die auf unserer Website (
            <a>www.commandalkon.com</a>) unter dem Reiter Rechtliches verfügbar ist, die weitere Einzelheiten enthält.{' '}
          </p>
          <h3>Informationen zum Standort:</h3>
          <p>
            Sie können standortbezogene Dienste ein- und ausschalten, indem Sie die Einstellungen Ihres Mobilgeräts
            anpassen. Bitte beachten Sie, dass, wenn Sie sich dafür entscheiden, standortbezogene Dienste auszuschalten,
            dies die volle Funktionalität unserer Produkte und Dienstleistungen beeinträchtigen könnte.{' '}
          </p>
          <h3>Datenschutzrechte in Kalifornien:</h3>
          <p>
            Nach kalifornischem Recht können Nutzer, die in Kalifornien ansässig sind, einmal pro Jahr kostenlos eine
            Liste der Dritten, an die wir ihre personenbezogenen Daten (falls vorhanden) für ihre Direktmarketingzwecke
            im vorangegangenen Kalenderjahr weitergegeben haben, sowie die Art der personenbezogenen Daten, die an diese
            Parteien weitergegeben wurden, anfordern und erhalten. Sofern in dieser Datenschutzerklärung oder gemäß
            Vereinbarung mit der betroffenen Person nichts anderes vorgesehen ist, verkauft CAI keine personenbezogenen
            Daten an Dritte für deren eigene Marketingzwecke.
          </p>
        </div>
      </div>
      <div id="international_data_transfers_and_privacy_shield">
        <div>
          <h2>Internationale Datenübertragungen und das Datenschutz-Framework &ndash;</h2>
          <p>
            CAI hat seinen Sitz in den Vereinigten Staaten und hat Niederlassungen auf der ganzen Welt. Um unseren
            Betrieb zu erleichtern und die Lieferung unserer Produkte und Dienstleistungen zu unterstützen, können wir
            personenbezogene Daten in die USA und jedes andere Land übertragen, in dem unsere verbundenen Unternehmen,
            Verkäufer, Berater und Dienstleister tätig sind. Solche Länder haben möglicherweise Gesetze, die anders und
            möglicherweise nicht so schützend sind wie die Gesetze Ihres Wohnsitzlandes. Wenn Sie ein
            Datenverantwortlicher sind, der CAI als Datenverarbeiter verwendet, und wenn die Inhalte oder Daten, die Sie
            auf oder über CAI-Software, Apps oder Websites speichern, personenbezogene Daten von Personen aus dem EWR
            enthalten, erklären Sie sich damit einverstanden, dass Sie rechtlich befugt sind, die personenbezogenen
            Daten an CAI zu übertragen, einschließlich der Übertragung in Länder wie die USA, in denen der Datenschutz
            und die Rechte der Behörden auf Zugriff auf personenbezogene Daten möglicherweise nicht denen im EWR
            entsprechen.
          </p>
          <p>
            Wenn wir personenbezogene Daten ins Ausland übertragen, werden wir angemessene Sicherheitsvorkehrungen
            treffen, um die Informationen gemäß dieser Datenschutzerklärung zu schützen und sicherzustellen, dass wir,
            zusammen mit allen Empfängern im Ausland, die geltenden Datenschutzgesetze einhalten. CAI stützt sich auf
            EU-Standardvertragsklauseln („SCCs“) für die legale Übertragung personenbezogener Daten zwischen seinen
            Unternehmen aus Ländern, die der Allgemeinen Datenschutzverordnung („DSGVO“) der EU („DSGVO“) unterliegen,
            in die USA. CAI stützt sich auf den Datenübertragungsvertrag des Vereinigten Königreichs für die legale
            Übertragung personenbezogener Daten zwischen seinen Unternehmen aus Ländern, die der Allgemeinen
            Datenschutzverordnung des Vereinigten Königreichs („UK GDPR“) unterliegen, in die USA.
          </p>
          <p>
            Zusätzlich zu den SCCs gilt: Wenn CAI erfährt, dass eine Regierungsbehörde (einschließlich
            Strafverfolgungsbehörden) Zugang zu oder eine Kopie von einigen oder allen der von CAI verarbeiteten
            personenbezogenen Daten erhalten möchte, ob auf freiwilliger oder obligatorischer Basis, für Zwecke der
            nationalen Sicherheitsnachrichtendienste, dann wird CAI: 1) unverzüglich die Partei benachrichtigen, auf die
            sich die personenbezogenen Daten beziehen (d. h. Kunde oder Verkäufer); 2) die zuständige Regierungsbehörde
            darüber informieren, dass dies nicht der Fall war berechtigt, die personenbezogenen Daten weiterzugeben und,
            sofern es nicht gesetzlich verboten ist, die Partei, für die die personenbezogenen Daten gelten,
            unverzüglich zu benachrichtigen; 3) die Regierungsbehörde darüber zu informieren, dass sie alle Anfragen
            oder Forderungen direkt an die Partei richten sollte, für die die personenbezogenen Daten gelten; und 4)
            keinen Zugriff auf die personenbezogenen Daten gewähren, bis sie von der Partei, für die die
            personenbezogenen Daten gelten, schriftlich genehmigt oder bis sie gesetzlich dazu gezwungen werden. Wenn
            CAI rechtlich dazu gezwungen ist, wird CAI angemessene und rechtmäßige Anstrengungen unternehmen, um ein
            solches Verbot oder einen solchen Zwang anzufechten. Wenn CAI gezwungen ist, die personenbezogenen Daten
            herauszugeben, wird CAI personenbezogene Daten nur in dem Umfang weitergeben, in dem dies gemäß dem
            geltenden rechtmäßigen Verfahren gesetzlich vorgeschrieben ist.
          </p>
          <p>
            CAI verwendet Datenschutzzusätze („DPAs“) mit Unterauftragsverarbeitern, die personenbezogene Daten im
            Auftrag von CAI verarbeiten, sofern dies nach den geltenden Datenschutzgesetzen erforderlich ist.
          </p>
          <p>
            Wenn wir personenbezogene Daten aus dem Europäischen Wirtschaftsraum („EWR“), dem Vereinigten Königreich
            oder der Schweiz in die Vereinigten Staaten übermitteln, hält sich CAI an das EU-U.S. Data Privacy Framework
            (EU-U.S. DPF), die britische Erweiterung des EU-U.S. DPF und das vom USHandelsministerium festgelegte
            Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF). CAI hat dem USHandelsministerium bescheinigt, dass es
            sich an das EU-U.S.-Abkommen hält. Grundsätze des Datenschutzrahmens (EU-U.S. DPF-Grundsätze) in Bezug auf
            die Verarbeitung personenbezogener Daten, die von der Europäischen Union im Vertrauen auf die EU-U.S. DPF
            und aus dem Vereinigten Königreich (und Gibraltar) im Vertrauen auf die UK-Erweiterung zum EU-U.S. DPF. CAI
            hat dem USHandelsministerium bescheinigt, dass es sich an das Swiss-U.S.-Abkommen hält. Grundsätze des
            Datenschutzrahmens (Swiss-U.S. DPF-Grundsätze) in Bezug auf die Verarbeitung personenbezogener Daten, die
            aus der Schweiz im Vertrauen auf die Swiss-U.S. DPF. Sollte es einen Konflikt zwischen den Bedingungen
            dieser Datenschutzerklärung und dem EU-U.S. DPF-Grundsätze und/oder das Swiss-U.S. DPF-Grundsätze, die
            Grundsätze gelten. Um mehr über das Data Privacy Framework (DPF)-Programm zu erfahren und unsere
            Zertifizierung einzusehen, besuchen Sie bitte <b>https://www.dataprivacyframework.gov/</b>.
          </p>
          <p>
            In Übereinstimmung mit dem EU-U.S. DPF, die britische Erweiterung des EU-U.S. DPF und das SwissU.S. DPF, CAI
            verpflichtet sich, Beschwerden im Zusammenhang mit den DPF-Grundsätzen über unsere Erhebung und Nutzung
            Ihrer personenbezogenen Daten zu lösen. Personen aus der EU, dem Vereinigten Königreich und der Schweiz mit
            Anfragen oder Beschwerden bezüglich unseres Umgangs mit personenbezogenen Daten, die wir im Vertrauen auf
            das EU-U.S.-Datenschutzabkommen erhalten haben. DPF, die britische Erweiterung des EU-U.S. DPF und/oder das
            Swiss-U.S. DPF sollte sich zunächst an CAI wenden, wie im Abschnitt „Kontakt“ am Ende dieser
            Datenschutzerklärung angegeben.
          </p>
          <p>
            Eine Einzelperson hat unter bestimmten Bedingungen die Möglichkeit, ein verbindliches Schiedsverfahren für
            Beschwerden bezüglich der DPF-Konformität anzurufen, die nicht durch einen der anderen DPFMechanismen gelöst
            werden können. Weitere Informationen finden Sie unter
            https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2. In Übereinstimmung
            mit dem EU-U.S. DPF, die britische Erweiterung des EU-U.S. DPF und das Swiss-U.S. DPF, CAI verpflichtet
            sich, ungelöste Beschwerden über unseren Umgang mit personenbezogenen Daten, die wir im Vertrauen auf das
            EU-U.S.-Abkommen erhalten haben, weiterzuleiten. DPF, die britische Erweiterung des EU-U.S. DPF und/oder das
            Swiss-U.S. DPF an JAMS, einen alternativen Streitbeilegungsanbieter mit Sitz in den Vereinigten Staaten.
            Wenn Sie von uns keine rechtzeitige Bestätigung Ihrer Beschwerde im Zusammenhang mit den DPF-Grundsätzen
            erhalten oder wenn wir Ihre Beschwerde im Zusammenhang mit den DPF-Grundsätzen nicht zu Ihrer Zufriedenheit
            bearbeitet haben, besuchen Sie bitte https://www.jamsadr.com/file-a-dpf -Forderung weiterer Informationen
            oder Einreichung einer Beschwerde. Die Dienste von JAMS stehen Ihnen kostenlos zur Verfügung.
          </p>
          <p>
            Eine Einzelperson hat unter bestimmten Bedingungen die Möglichkeit, ein verbindliches Schiedsverfahren für
            Beschwerden bezüglich der DPF-Konformität anzurufen, die nicht durch einen der anderen DPFMechanismen gelöst
            werden können. Weitere Informationen finden Sie unter
            https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2. In Übereinstimmung
            mit dem EU-U.S. DPF, die britische Erweiterung des EU-U.S. DPF und das Swiss-U.S. DPF, CAI verpflichtet
            sich, ungelöste Beschwerden über unseren Umgang mit personenbezogenen Daten, die wir im Vertrauen auf das
            EU-U.S.-Abkommen erhalten haben, weiterzuleiten. DPF, die britische Erweiterung des EU-U.S. DPF und/oder das
            Swiss-U.S. DPF an JAMS, einen alternativen Streitbeilegungsanbieter mit Sitz in den Vereinigten Staaten.
            Wenn Sie von uns keine rechtzeitige Bestätigung Ihrer Beschwerde im Zusammenhang mit den DPF-Grundsätzen
            erhalten oder wenn wir Ihre Beschwerde im Zusammenhang mit den DPF-Grundsätzen nicht zu Ihrer Zufriedenheit
            bearbeitet haben, besuchen Sie bitte <b>https://www.jamsadr.com/file-a-dpf</b> -Forderung weiterer
            Informationen oder Einreichung einer Beschwerde. Die Dienste von JAMS stehen Ihnen kostenlos zur Verfügung.
          </p>
        </div>
      </div>
      <div id="lawful_basis_for_processing">
        <div>
          <h2>
            Rechtliche Grundlage für die Verarbeitung personenbezogener Daten von Einzelpersonen (EWR/Großbritannien)
            &ndash;
          </h2>
          <p>
            Wenn Sie eine Person aus dem EWR oder Großbritannien sind, hängt unsere Rechtsgrundlage für die Erhebung und
            Verwendung personenbezogener Daten von den betreffenden personenbezogenen Daten und dem spezifischen Kontext
            ab, in dem wir sie erheben. Wir werden Ihre personenbezogenen Daten nur für die Zwecke verwenden, für die
            wir diese personenbezogenen Daten erheben, wie unten beschrieben, es sei denn, wir müssen sie zu einem
            späteren Zeitpunkt für einen anderen Zweck verwenden, der mit dem ursprünglichen Zweck vereinbar ist. Wenn
            wir Ihre personenbezogenen Daten für einen Zweck weiterverarbeiten müssen, der nicht mit dem ursprünglichen
            Zweck der Erfassung vereinbar ist, werden wir Sie benachrichtigen und Ihnen die Rechtsgrundlage erläutern,
            die uns dies ermöglicht. Im Allgemeinen stützen wir uns jedoch auf die folgenden rechtmäßigen Begründungen
            für unsere Verarbeitungsaktivitäten: 1) die Erfüllung einer Vereinbarung mit Ihnen und/oder Ihrem
            Arbeitgeber; 2) Verfolgung unserer legitimen Interessen, solange sie Ihre Interessen, Rechte und Freiheiten
            nicht überwiegen; 3) Ihre Zustimmung; und 4) Einhaltung einer gesetzlichen Verpflichtung. In einigen Fällen
            können wir uns auch auf Ausnahmeregelungen nach Artikel 49 der DSGVO verlassen, sind gesetzlich
            verpflichtet, personenbezogene Daten zu erheben, oder benötigen die personenbezogenen Daten auf andere
            Weise, um Ihre vitalen Interessen oder die einer anderen Person zu schützen. Weitere Einzelheiten zur
            Rechtsgrundlage finden Sie weiter unten.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Zweck (e) der Verarbeitung</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Rechtliche Grundlage für die Verarbeitung</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Um Anträge und andere Verträge für unsere Produkte und Dienstleistungen zu bearbeiten.</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten ist notwendig, um einen Vertrag zu erfüllen oder
                        einen Vertrag mit Ihnen und/oder Ihrem Arbeitgeber abzuschließen.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Um Verträge, einschließlich Serviceverträge, mit Ihnen und Ihrem Arbeitgeber zu verwalten und zu
                    verwalten.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten ist notwendig, damit wir gesetzlichen und
                        behördlichen Verpflichtungen nachkommen können.
                      </p>
                    </li>
                    <li>
                      <p>
                        Die Verarbeitung ist notwendig, um unsere legitimen Interessen an der Verwaltung unseres
                        Geschäfts (oder der eines Dritten) zu unterstützen, sofern diese Interessen nicht durch Ihre
                        Interessen, Rechte und Freiheiten überlagert werden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Um unsere Produkte und Dienstleistungen zu verbessern, Marktforschung zu betreiben, Datenanalysen
                    durchzuführen, für allgemeine Risikomodellierungszwecke und für statistische Analysen{' '}
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung ist notwendig, um unsere legitimen Interessen an der Verwaltung unseres
                        Geschäfts (oder der eines Dritten) zu unterstützen, sofern diese Interessen nicht durch Ihre
                        Interessen, Rechte und Freiheiten überlagert werden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Marketing</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Wir werden Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen Daten für Marketingzwecke
                        einholen — die Sie jederzeit widerrufen können.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Zur Verhinderung und Aufdeckung von Betrug, Geldwäsche oder anderen Verbrechen</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten ist notwendig, damit wir gesetzlichen und
                        behördlichen Verpflichtungen nachkommen können, oder soweit dies nach geltendem Recht zulässig
                        ist.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Um unsere Beziehung zu Ihnen und zwischen Ihnen und Ihrem Arbeitgeber zu verwalten</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten ist notwendig, um einen Vertrag zu erfüllen oder
                        einen Vertrag mit Ihnen und/oder Ihrem Arbeitgeber abzuschließen.
                      </p>
                    </li>
                    <li>
                      <p>
                        Die Verarbeitung ist notwendig, um unsere legitimen Interessen an der Verwaltung unseres
                        Geschäfts (oder der eines Dritten) zu unterstützen, sofern diese Interessen nicht durch Ihre
                        Interessen, Rechte und Freiheiten überlagert werden
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Um die Vorteile unserer Produkte und Dienstleistungen zu bieten</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten ist notwendig, um einen Vertrag zu erfüllen oder
                        einen Vertrag mit Ihnen und/oder Ihrem Arbeitgeber abzuschließen.
                      </p>
                    </li>
                    <li>
                      <p>
                        Die Verarbeitung ist notwendig, um unsere legitimen Interessen an der Verwaltung unseres
                        Geschäfts (oder der eines Dritten) zu unterstützen, sofern diese Interessen nicht durch Ihre
                        Interessen, Rechte und Freiheiten überlagert werden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="children_policy">
        <div>
          <h2>Datenschutz für Kinder &ndash;</h2>
          <p>
            Das Geschäft von CAI richtet sich nicht an Kinder und CAI sammelt oder erbittet wissentlich keine
            Informationen von Kindern oder Personen unter 13 Jahren. CAI erlaubt solchen Personen nicht wissentlich,
            unsere Produkte oder Dienstleistungen zu nutzen. Falls wir erfahren, dass wir versehentlich personenbezogene
            Daten von einem Kind oder einer Person unter 13 Jahren gesammelt haben, werden wir diese Informationen so
            schnell wie möglich löschen. Wenn Sie glauben, dass wir Informationen von oder über ein Kind haben könnten,
            kontaktieren Sie uns bitte über die unten im Abschnitt „Kontakt“ am Ende dieser Datenschutzerklärung
            aufgeführten Methoden.
          </p>
        </div>
      </div>
      <div id="changes_to_the_privacy_notice">
        <div>
          <h2>Änderungen der Datenschutzerklärung &ndash;</h2>
          <p>
            Wir können diese Datenschutzerklärung jederzeit ändern, indem wir die geänderte Datenschutzerklärung auf
            unseren Websites veröffentlichen. Das Datum, an dem die Datenschutzerklärung zuletzt überarbeitet wurde, ist
            oben in dieser Datenschutzerklärung angegeben. Alle geänderten Bedingungen treten automatisch in Kraft,
            nachdem sie zum ersten Mal auf unserer Website veröffentlicht wurden, es sei denn, eine Änderung des
            geltenden Rechts erfordert eine sofortige Änderung. Sie sind dafür verantwortlich, diese
            Datenschutzerklärung regelmäßig auf Änderungen zu überprüfen.
          </p>
        </div>
      </div>
      <div>
        <div>
          <h2>Beschwerden &ndash;</h2>
          <p>
            Unbeschadet aller anderen administrativen oder gerichtlichen Rechtsbehelfe, die Ihnen zur Verfügung stehen,
            haben Sie möglicherweise das Recht, eine Beschwerde bei den örtlichen Aufsichtsbehörden/Datenschutzbehörden
            einzureichen, wenn Sie der Meinung sind, dass wir bei der Verarbeitung personenbezogener Daten über Sie
            gegen geltende Datenschutz- oder Datenschutzvorschriften verstoßen haben.{' '}
          </p>
          <h3>Im Vereinigten Königreich können Sie Kontakt aufnehmen mit: </h3>
          <p>Das Büro des Informationskommissars </p>
          <p>
            Water Lane, Wycliffe House
            <br />
            Wilmslow &#8211; Cheshire SK9 5AF
          </p>
          <p>Tel. +44 1625 545 700</p>
          <p>
            Email: <b>casework@ico.org.uk</b>
          </p>
          <p>
            Website: <b>https://ico.org.uk</b>
          </p>
          <h3>Für die EU lesen Sie bitte: </h3>
          <p>
            <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
          </p>
        </div>
      </div>
      <div id="contact_us">
        <div>
          <h2>Kontaktieren Sie uns &ndash;</h2>
          <p>
            Um CAI bei Fragen oder Bedenken zu dieser Datenschutzerklärung oder den Praktiken von CAI in Bezug auf
            personenbezogene Daten zu kontaktieren, verwenden Sie bitte die folgenden Kontaktinformationen: Wenn Sie im
            EWR ansässig sind, beachten Sie bitte, dass dort, wo CAI als Datenverantwortlicher für Ihre
            personenbezogenen Daten fungiert, die juristische Person Command Alkon Incorporated in den Vereinigten
            Staaten ist. CAI wird alle Fragen und Beschwerden im Zusammenhang mit dieser Datenschutzerklärung innerhalb
            einer angemessenen Frist bearbeiten.{' '}
          </p>
          <p>
            <strong>E-mail:</strong>
          </p>
          <p>
            <b>privacy@commandalkon.com</b>
          </p>
          <p>
            <strong>Or write to:</strong>
          </p>
          <p>Command Alkon Incorporated</p>
          <p>Chief Privacy Officer c/o The Legal Department</p>
          <p>
            1800 International Park Drive
            <br />
            Suite 400
            <br />
            Birmingham, Alabama 35243
          </p>
          <p>
            <strong>Or call:</strong>
          </p>
          <p>1-800-624-1872 (U.S. toll free)</p>
          <p>0-800-022-9682 (International toll free)</p>
          <p>
            <strong>Website:</strong>
          </p>
          <p>
            <b>www.commandalkon.com</b>
          </p>
        </div>
      </div>
    </Styled>
  );
};
