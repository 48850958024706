import { Drawer, Form, Localization, usePostMessageContext } from 'connex-cds';
import { cloneDeep, find, omit } from 'lodash';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DataStore } from './datastore/DataStore';
import FormSubmit from './FormSubmit';
import { useFormConfig } from './get-form-config';
import {
  useCompanySetup,
  useMaterialComplianceSetup,
  useProductSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from './MasterDataProvider';
import { MobileTicketErrorBoundary } from './MobileTicketErrorBoundary';
import { RefreshMonitor } from './RefreshMonitor';
import { TicketContextProvider, useTicketContext } from './TicketContext';
import { useInitializeMasterData } from './useInitializeMasterData';
import { useMonitorTimeZone } from './useMonitorTimeZone';
import { Wizard } from './wizard/Wizard';
import { getCachedItem } from '../../../../util/cache';

const { pick } = require('lodash');

const { FormProvider } = Form;
const { DrawerProvider } = Drawer;

const MobileTicket = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { ticket, setFinalized } = useTicketContext();
  const navigate = useNavigate();
  const loadedQuantity = React.useMemo(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });
    return primaryLineItem?.quantity;
  }, [ticket]);

  const materialComplianceSetup = useMaterialComplianceSetup();

  const airCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess && materialComplianceSetup?.data) {
      const materialCompliance = materialComplianceSetup?.data
        ?.filter?.(compliance => compliance.status === 'ACTIVE')
        ?.filter?.(compliance => compliance.classType === 'AIR');

      if (materialCompliance && materialCompliance?.length) {
        const materialComplianceName = translateObjects(materialCompliance, {
          getStringId: x => `SETUP_CONCRETE-CLASSES_${x}-NAME`.replace(/\s/gi, '_').toUpperCase(),
          getPath: 'id',
          setPath: 'labelName',
          defaultMessagePath: 'name',
        });

        const materialComplianceDescription = translateObjects(materialComplianceName, {
          getStringId: x => `SETUP_CONCRETE-CLASSES_${x}-DESCRIPTION`.replace(/\s/gi, '_').toUpperCase(),
          getPath: 'id',
          setPath: 'labelDescription',
          defaultMessagePath: 'description',
        });

        return materialComplianceDescription;
      }

      return materialCompliance;
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const consistenceCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess && materialComplianceSetup?.data) {
      const materialCompliance = materialComplianceSetup?.data
        ?.filter?.(compliance => compliance.status === 'ACTIVE')
        ?.filter?.(compliance => compliance.classType === 'CONSISTENCE');
      if (materialCompliance && materialCompliance?.length) {
        const materialComplianceName = translateObjects(materialCompliance, {
          getStringId: x => `SETUP_CONCRETE-CLASSES_${x}-NAME`.replace(/\s/gi, '_').toUpperCase(),
          getPath: 'id',
          setPath: 'labelName',
          defaultMessagePath: 'name',
        });

        const materialComplianceDescription = translateObjects(materialComplianceName, {
          getStringId: x => `SETUP_CONCRETE-CLASSES_${x}-DESCRIPTION`.replace(/\s/gi, '_').toUpperCase(),
          getPath: 'id',
          setPath: 'labelDescription',
          defaultMessagePath: 'description',
        });

        return materialComplianceDescription;
      }

      return materialCompliance;
    }

    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);
  const formConfig = useFormConfig(loadedQuantity, ticket?.vehicleId, airCompliance?.[0], consistenceCompliance?.[0], primaryLineItem);

  const companySetup = useCompanySetup();
  const productSetup = useProductSetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const mandatoryWaterItem = React.useMemo(() => {
    if (
      companySetup?.data?.isWaterMandatory &&
      !!companySetup?.data?.isWaterInWorkflow &&
      (!!vehicleTypeData?.isWaterInWorkflow || !vehicleTypeData)
    ) {
      const defaultWaterRef = companySetup?.data?.defaultWaterProduct?.productRef;
      const defaultWater = {
        ...omit(find(productSetup?.data, { crn: defaultWaterRef }), 'crn'),
        productRef: defaultWaterRef,
      };
      return {
        waterProduct: defaultWater,
        quantity: {
          uomCode: defaultWater.uomCode,
        },
        suggestedToDriver: true,
      };
    }
    return null;
  }, [
    companySetup?.data?.defaultWaterProduct?.productRef,
    companySetup?.data?.isWaterInWorkflow,
    companySetup?.data?.isWaterMandatory,
    productSetup?.data,
    vehicleTypeData,
  ]);

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [vehicleSetup.data, ticket?.vehicleId]);

  const setupMandatoryItems = React.useMemo(
    () =>
      productSetup?.data
        ?.filter?.(p => p.isDriverSellable)
        ?.filter?.(product => product.status === 'ACTIVE')
        ?.filter?.(product =>
          companySetup?.data?.isMulticountry === true
            ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
            : product
        )
        ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length)
        ?.filter?.(p => p.mobileTicket?.mandatory === true),
    [companySetup?.data?.isMulticountry, productSetup?.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  const formattedMandatoryItems = React.useMemo(
    () =>
      setupMandatoryItems?.map?.(mandatoryItem => ({
        item: {
          ...pick(mandatoryItem, [
            'createDate',
            'name',
            'id',
            'isDriverSellable',
            'mobileTicket',
            'modifyDate',
            'productRef',
            'status',
            'type',
            'typeId',
            'uomCode',
            'i18n',
          ]),
          productRef: mandatoryItem.crn,
        },
        quantity: {
          uomCode: mandatoryItem.uomCode,
        },
        suggestedToDriver: true,
      })),
    [setupMandatoryItems]
  );

  const initialValues = React.useMemo(() => {
    const state = getCachedItem('state');
    if (state) {
      const stateObj = JSON.parse(state);
      if (stateObj[ticket.crn]) {
        if (stateObj[ticket.crn]?.signature?.image?.length > 0) {
          setFinalized(true);
        }
        return stateObj[ticket.crn];
      }
    }

    if (!formattedMandatoryItems) {
      return;
    }

    const cloned = cloneDeep(ticket);

    let mandatoryItems = [];
    if (
      !!companySetup?.data?.isMaterialServiceInWorkflow &&
      (!!vehicleTypeData?.isMaterialServiceInWorkflow || !vehicleTypeData)
    ) {
      if (formattedMandatoryItems.length) {
        const upFront = cloned?.lineItems
          ?.filter?.(item => !item.isPrimary)
          ?.filter?.(item => !item.isSplitLoad)
          .filter?.(item => item.requiresDriverReview);
        for (let i = 0; i < formattedMandatoryItems.length; i++) {
          const existing = upFront.find(o => o.item.id === formattedMandatoryItems[i]?.item?.id);
          if (!existing) {
            mandatoryItems.push(formattedMandatoryItems[i]);
          }
        }
      }
    }

    return {
      lineItems: mandatoryItems.length
        ? cloned?.lineItems
            .filter(item => !item.isPrimary)
            .filter(item => !item.isSplitLoad)
            .concat(mandatoryItems)
        : cloned?.lineItems.filter(item => !item.isPrimary).filter(item => !item.isSplitLoad),
      waterAddedEvents: mandatoryWaterItem ? [mandatoryWaterItem] : [],
      ticketRef: cloned?.crn,
      ticketEvents: cloned?.ticketEvents,
      unloadingMethod: cloned?.unloadingMethod,
    };
  }, [
    companySetup?.data?.isMaterialServiceInWorkflow,
    formattedMandatoryItems,
    mandatoryWaterItem,
    setFinalized,
    ticket,
  ]);

  React.useEffect(() => {
    navigate('ticketDetail');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialValues) {
    return null;
  }

  return (
    <FormProvider config={formConfig} initialValues={initialValues}>
      <RefreshMonitor>
        <DrawerProvider>
          <FormSubmit>
            <Routes>
              <Route path=":stepId" element={<Wizard />} />
            </Routes>
          </FormSubmit>
        </DrawerProvider>
      </RefreshMonitor>
    </FormProvider>
  );
};

const Styled = styled.div`
  height: 100%;

  @media (max-width: 950px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export default ({ driverExperience }) => {
  const { sendMessage } = usePostMessageContext();
  useInitializeMasterData();
  useMonitorTimeZone();

  React.useEffect(() => {
    sendMessage(JSON.stringify({ loaded: true }));
    sendMessage(JSON.stringify({ type: 'debug', message: 'Mobile Ticket Loaded' }));
  }, [sendMessage]);

  return (
    <MobileTicketErrorBoundary>
      <Styled>
        <DataStore>
          <TicketContextProvider driverExperience={driverExperience}>
            <MobileTicket />
          </TicketContextProvider>
        </DataStore>
      </Styled>
    </MobileTicketErrorBoundary>
  );
};
