import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from '../../style';
import { useCompanySetup, useUnloadingMethodSetup, useVehicleSetup } from './MasterDataProvider';
import { useTicketContext } from './TicketContext';

const { useFormContext } = Form;

const Styled = styled.div`
  ${style}
`;
const UnloadingMethodForm = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const {
    Components: { UnloadingMethod },
  } = useFormContext();

  const { ticket } = useTicketContext();

  const vehicleSetup = useVehicleSetup();
  const unloadingMethodSetup = useUnloadingMethodSetup();
  const companySetup = useCompanySetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [ticket?.vehicleId, vehicleSetup.data]);

  const applicableUnloadingMethods = React.useMemo(() => {
    if (!unloadingMethodSetup?.data) {
      return [];
    }
    const unloadingMethods = unloadingMethodSetup?.data
      ?.filter?.(unloadingMethod => unloadingMethod.status === 'ACTIVE')
      ?.filter?.(unloadingMethod =>
        companySetup?.data?.isMulticountry === true
          ? unloadingMethod?.countries?.includes(ticket?.origin?.address?.countryCode) ||
            !unloadingMethod?.countries?.length
          : unloadingMethod
      )
      ?.filter?.(
        unloadingMethod =>
          unloadingMethod?.vehicleTypes?.includes(vehicleType) || !unloadingMethod?.vehicleTypes?.length
      );

    const unloadingMethodsTranslated = translateObjects(unloadingMethods, {
      getStringId: x => `SETUP_UNLOADING-METHOD_${x}`.replace(/\s/gi, '_').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'description',
    });

    if (!unloadingMethodsTranslated) {
      return unloadingMethods;
    }

    return unloadingMethodsTranslated.map(method => ({
      crn: method?.crn,
      description: method?.label ?? method?.description,
    }));
  }, [
    companySetup?.data?.isMulticountry,
    ticket?.origin?.address?.countryCode,
    unloadingMethodSetup?.data,
    vehicleType,
    translateObjects,
  ]);

  return (
    <Styled className={cn('the-form')}>
      <UnloadingMethod
        options={applicableUnloadingMethods}
        busy={vehicleSetup.isLoading || unloadingMethodSetup.isLoading}
        showSearch={false}
      />
    </Styled>
  );
};

export default UnloadingMethodForm;
