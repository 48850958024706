import { Form, Localization } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-water';

export const WaterAdd = () => {
  const { values } = Form.useFormContext();

  if (!values?.waterAddedEvents?.length) {
    return null;
  }

  return values.waterAddedEvents
    .filter(waterAddedEvent => !waterAddedEvent.driverDidNotAdd)
    .map((waterAddedEvents, index) => (
      <tr>
        <td data-testid={`${testId}-row-${index}-activity-value`}>
          <Localization.Translate stringId="waterAdded" data-testid="returnedConcrete-label" />
        </td>
        <td data-testid={`${testId}-row-${index}-quantity-value`}>
          <Localization.Uom uom={waterAddedEvents.quantity} />
        </td>
        <td data-testid={`${testId}-row-${index}-concrete-value`}>
          <Localization.Uom uom={waterAddedEvents.concreteOnTruck} />
        </td>
        <td data-testid={`${testId}-row-${index}-reason-value`}>{waterAddedEvents.reason?.description}</td>
      </tr>
    ));
};
