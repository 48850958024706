import React from 'react';
import { Localization } from 'connex-cds';

const testId = 'activityGrid-productSvc';

export const ProductsServices = ({ lineItems }) => {
  if (!lineItems?.length) {
    return null;
  }

  return lineItems?.map((materialService, index) => (
    <tr>
      <td data-testid={`${testId}-row-${index}-activity-value`}>{materialService?.label}</td>
      <td data-testid={`${testId}-row-${index}-quantity-value`}>
        <Localization.Uom uom={materialService?.quantity} />
      </td>
      <td data-testid={`${testId}-row-${index}-concrete-value`}>
        <Localization.Uom uom={materialService?.concreteOnTruck} />
      </td>
      <td data-testid={`${testId}-row-${index}-reason-value`}>{materialService?.reason?.description}</td>
    </tr>
  ));
};
