import React from 'react';
import { find } from 'lodash';
import * as yup from 'yup';
import { useCompanySetup, useProductSetup } from '../MasterDataProvider';

export const useWaterAddedSchema = loadedQuantity => {
  const { data: products } = useProductSetup();
  const companySetup = useCompanySetup();

  const schema = React.useMemo(() => {
    return yup.object().shape({
      waterProduct: yup.object().required('required'),
      driverDidNotAdd: yup.boolean(),
      quantity: yup.object().when(['waterProduct', 'driverDidNotAdd'], {
        is: (waterProduct, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          return true;
        },
        then: schema =>
          schema.shape({
            value: yup.number().required('required'),
            uomCode: yup.string().required('required'),
          }),
        otherwise: schema => schema,
      }),
      reason: yup.object().when(['waterProduct', 'driverDidNotAdd'], {
        is: (waterProduct, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: waterProduct?.productRef });
          return targetProduct?.mobileTicket?.isReasonFieldEnabled;
        },
        then: schema => schema.required('required'),
        otherwise: schema => schema,
      }),
      concreteOnTruck: yup.object().when(['waterProduct', 'driverDidNotAdd'], {
        is: (waterProduct, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: waterProduct?.productRef });
          return targetProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled;
        },
        then: schema =>
          schema.shape({
            value: yup
              .number()
              .max(loadedQuantity.value, {
                key: 'exceedsLoadedQuantity',
                values: { loadedQuantity: loadedQuantity.value },
              })
              .required('required'),
            uomCode: yup.string().required('required'),
          }),
        otherwise: schema => schema,
      }),
      time: yup.mixed().when(['waterProduct', 'driverDidNotAdd'], {
        is: (waterProduct, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: waterProduct?.productRef });
          return targetProduct?.mobileTicket?.isTimeFieldEnabled;
        },
        then: schema => schema.required('required'),
        otherwise: schema => schema,
      }),
      waterLocation: companySetup?.data?.isDotEnabled ? yup.object().required('required') : yup.object(),
    });
  }, [products]);

  return schema;
};
